import { useMutation } from '@apollo/react-hooks';
import { Button, Icon, message, Modal, Popconfirm, Switch, Table, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import DELETE_SESSION from '../../graphql/mutations/deleteSession';
import DUPLICATE_SESSION from '../../graphql/mutations/duplicateSession';
import UPDATE_SESSION from '../../graphql/mutations/updateSession';

import useLogin from '../../hooks/useLogin';
import useTableFilter from '../../hooks/useTableFilter';
import { useEvent, useEventSessions } from '../../hooks/providers/events';

// @todo: Should probably makes the queries with limits.
function SessionsList({ formChanged }) {
  // we need user object here
  const { isModerator } = useLogin();
  const [event] = useEvent();
  // eslint-disable-next-line no-unused-vars
  const [sessions, { reloadSessions }] = useEventSessions();

  const { confirm } = Modal;
  const [newSession, setNewSession] = useState(false);
  const [structuredTable, setStructuredTable] = useState(false);
  const [duplicateSession] = useMutation(DUPLICATE_SESSION, {
    refetchQueries: ['sessions'],
  });
  const [updateSession] = useMutation(UPDATE_SESSION, {
    refetchQueries: ['sessions'],
  });
  const [deleteSession] = useMutation(DELETE_SESSION);

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const nameFilters = useTableFilter('name');

  const handleDuplicateClick = (session_id, session_name) => {
    confirm({
      title: 'Are you sure you want to duplicate this session?',
      content: 'This copies the session and its contents by adding "-COPY" to the end of the session name',
      async onOk() {
        const name = `${session_name}-COPY`;
        try {
          const result = await duplicateSession({
            variables: {
              // TODO: Refactor episode_id -> session_id
              payload: { episode_id: session_id, name },
            },
          });
          if (result) {
            message.success('Successfully duplicated session');
            await reloadSessions();
          }
        } catch (e) {
          message.error('Something went wrong');
        }
      },
    });
  };
  const handleDeleteClick = async (e, session_id) => {
    e.stopPropagation();
    try {
      const result = await deleteSession({
        variables: { session_id },
      });
      if (result) {
        const { session } = result.data;
        message.success(`Successfully deleted session: ${session.name}`);
        await reloadSessions();
      }
    } catch (err) {
      message.error('Something went wrong');
      message.error(err);
    }
  };

  const handleLiveChange = async (record) => {
    try {
      await updateSession({
        variables: {
          session_id: record.id,
          payload: {
            is_live: !record.isLive,
          },
        },
      });
      message.success('Session saved');
      await reloadSessions();
    } catch (err) {
      message.error('Something went wrong');
      message.error(err);
    }
  };

  const handleClickCreateSession = useCallback((e) => {
    e.stopPropagation();
    // TODO
    if (formChanged) {
      confirm({
        title: 'You have made changes to the event form',
        content: 'Are you sure you want to navigate to an session before saving?',
        onOk() {
          setNewSession(true);
        },
      });
    } else {
      setNewSession(true);
    }
  }, [formChanged, setNewSession, confirm]);
  useEffect(() => {
    if (!event) return;
    if (!sessions) return;

    const tableData = sessions.map((session) => ({
      key: session.id,
      id: session.id,
      name: session.name,
      description: session.description,
      isLive: session.is_live,
    }));
    const columns = [
      {
        title: 'Session information',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name),
        render: (_, { id, name, description }) => (
          <NavLink
            to={`/events/${event.id}/sessions/${id}`}
            style={{ display: 'flex', flexDirection: 'column', color: 'unset' }}
          >
            <span>{name}</span>
            {description ? (
              <small style={{ maxHeight: '1.6em', overflowY: 'hidden' }}>
                {description}
              </small>
            ) : (
              <small style={{ opacity: 0.35 }}>No description</small>
            )}
          </NavLink>
        ),
        ...nameFilters,
      },
      {
        title: isModerator ? '' : 'Is Live',
        key: 'action_isLive',
        width: 100,
        onCell: () => ({
          onClick: (e) => e.stopPropagation(),
        }),
        render: (text, record) => {
          if (isModerator) {
            return '';
          }

          return (
            <Switch
              onChange={() => handleLiveChange(record)}
              defaultChecked={record.isLive}
              checked={record.isLive}
            />
          );
        },
      },
      {
        title: '',
        key: 'actions',
        width: 200,
        onCell: () => ({
          onClick: (e) => e.stopPropagation(),
        }),
        render: (text, record) => {
          if (isModerator) {
            return '';
          }

          return (
            <>
              <Tooltip title="Duplicates this session">
                <Button
                  type="link"
                  size="small"
                  onClick={() => handleDuplicateClick(record.id, record.name)}
                >
                  <Icon type="copy" />
                </Button>
              </Tooltip>
              <Popconfirm
                title="Are you sure you want to delete this session?"
                onConfirm={(e) => handleDeleteClick(e, record.id)}
              >
                <Button type="link" size="small">
                  <Icon type="delete" />
                </Button>
              </Popconfirm>
            </>
          );
        },
      },
    ];
    setStructuredTable({ columns, tableData });
  }, [event, sessions, setStructuredTable]);

  // if a edit session id has been set, redirect to edit page
  if (newSession) {
    return <Redirect push to={`/events/${event.id}/sessions/create`} />;
  }

  return (
    <div>
      <header className="page-header">
        <h2>Sessions</h2>
        {!isModerator && (
          <Button type="primary" size="default" onClick={(e) => handleClickCreateSession(e)}>
            Add Session
          </Button>
        )}
      </header>

      {structuredTable && (
        <Table
          columns={structuredTable.columns}
          dataSource={structuredTable.tableData}
          size="small"
          pagination={{
            current: currentPage,
            pageSize: currentPageSize,
            onChange(page, pageSize) {
              setCurrentPage(page);
              setCurrentPageSize(pageSize);
            },
            onShowSizeChange(page, pageSize) {
              setCurrentPage(page);
              setCurrentPageSize(pageSize);
            },
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
          }}
        />
      )}
    </div>
  );
}

export default SessionsList;
