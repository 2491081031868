import React, { useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import ModuleForm from '../../components/ModuleForm/ModuleForm';
import BreadCrumbs from '../../components/Breadcrumbs';

import MODULE_ADD from '../../graphql/mutations/moduleAdd';
import OPTION_ADD from '../../graphql/mutations/optionAdd';
import MODULE_LIST from '../../graphql/queries/listModules';
import GET_SESSION from '../../graphql/queries/getSession';
import { useMappedParams } from '../../hooks/utils';
import { EventProvider } from '../../providers/EventProvider';
import { SessionProvider } from '../../providers/SessionProvider';

function CreateModulePage() {
  const { eventId, sessionId } = useMappedParams({
    eventId: (params) => +params.event_id,
    sessionId: (params) => +params.session_id,
  });
  return (
    <EventProvider eventId={eventId} refetch={false}>
      <SessionProvider sessionId={sessionId} refetch={false}>
        <CreateModuleWrapper />
      </SessionProvider>
    </EventProvider>
  );
}

function CreateModuleWrapper() {
  // local state variables
  const [redirect, setRedirect] = useState('');
  const { eventId, sessionId } = useMappedParams({
    eventId: (params) => +params.event_id,
    sessionId: (params) => +params.session_id,
  });
  const [breadcrumbs, setBreadcrumbs] = useState([{
    breadcrumbName: 'Create new module',
    path: false,
  }]);
  // graphql
  const [addModule] = useMutation(MODULE_ADD, {
    refetchQueries: [{
      query: MODULE_LIST,
      variables: { session_id: sessionId },
    }],
  });
  const [addOption] = useMutation(OPTION_ADD);

  const { data: sessionData } = useQuery(GET_SESSION, {
    variables: { session_id: sessionId },
    skip: !sessionId,
  });
  const client = useMemo(() => {
    if (!sessionData) return null;
    if (!sessionData.session) return null;
    if (!sessionData.session.event) return null;
    return sessionData.session.event.client;
  }, [sessionData]);

  useEffect(() => {
    if (sessionData) {
      setBreadcrumbs((bcs) => (
        [
          {
            breadcrumbName: sessionData.session.event.name,
            path: `/events/${sessionData.session.event.id}`,
          },
          {
            breadcrumbName: sessionData.session.name,
            path: `/events/${sessionData.session.event.id}/sessions/${sessionData.session.id}`,
          },
          ...bcs,
        ]
      ));
    }
  }, [sessionData]);
  const onSubmit = async (payload) => {
    const { option, options, ...data } = payload;

    if (payload.type === 'product_launch') {
      data.subtitle_ended = '';
      data.subtitle_not_started = '';
    }

    // @todo: Refactor into form before submit to set default values, if they don't exist
    // This right here, is quite fucky
    // Doing this, because some modules don't have these fields, but database is set up to not allow NULL values
    if (payload.price) {
      data.price = parseFloat(payload.price.replace('€ ', ''));
    } else {
      data.price = 0;
    }
    if (payload.megavote_price) {
      data.megavote_price = parseFloat(payload.megavote_price.replace('€ ', ''));
    } else {
      data.megavote_price = 0;
    }
    if (payload.megavote_amount) {
      data.megavote_amount = parseInt(payload.megavote_amount, 10);
    } else {
      data.megavote_amount = 0;
    }
    if (!payload.show_results) {
      data.show_results = false;
    }
    if (!payload.show_total_count) {
      data.show_total_count = false;
    }
    if (!payload.show_all_comments) {
      data.show_all_comments = false;
    }

    // by default show own comments is same as all comments (not yet used)
    data.show_own_comments = data.show_all_comments;

    // Service should take care of inserting the correct number for this
    if (!payload.weight) {
      data.weight = 0;
    }
    // TODO: Refactor episode_id -> session_id
    data.episode_id = sessionId;
    try {
      const result = await addModule({
        variables: {
          payload: data,
        },
      });
      if (result) {
        if (payload.type === 'reaction' && options && options.length > 0) {
          for (let index = 0; index < options.length; index++) {
            const single_option = options[index];
            const optionPayload = {
              title: single_option,
              subtitle: single_option,
              image: single_option,
              weight: 0,
              published: true,
            };
            try {
              // eslint-disable-next-line no-await-in-loop
              await addOption({
                variables: {
                  payload: {
                    module_id: result.data.createModule.id,
                    ...optionPayload,
                  },
                },
              });
            } catch (err) {
              message.error('Something went wrong');
              console.error(err);
            }
          }
        }
        const { id, title } = result.data.createModule;
        message.success(`Successfully created module: ${title}`);
        setRedirect(`/events/${eventId}/sessions/${sessionId}/modules/${id}`);
      }
    } catch (err) {
      message.error('Something went wrong');
      console.error(err);
    }
  };
  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <div>
      <BreadCrumbs items={breadcrumbs} />
      <ModuleForm
        type="create"
        eventId={eventId}
        sessionId={sessionId}
        onSubmit={onSubmit}
        client={client}
      />
    </div>
  );
}

export default CreateModulePage;
