import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import useLogin from '../../hooks/useLogin';

// eslint-disable-next-line react/prop-types
export default function ({ component: Component, ...rest }) {
  const { user, loading } = useLogin();

  if (loading) {
    return null;
  }

  if (!user) {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      render={
        (props) => <Component {...props} />
      }
      {...rest}
    />
  );
}
