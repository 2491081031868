import QR from 'qrcode';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';

const CanvasContainer = styled.div`
  padding: 16px;
  background-color: #ffffff;
  border-radius: 15px;
`;

function QRCode({ refCanvas, data, options }) {
  const canvas = useRef();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (canvas.current) {
      QR.toCanvas(canvas.current, data, options, (err) => {
        if (err) setError(err);
        if (refCanvas) {
          // eslint-disable-next-line no-param-reassign
          refCanvas.current = canvas.current;
        }
      });
    }
  }, [refCanvas, data, options, canvas]);

  if (error) {
    return null;
  }

  return (
    <CanvasContainer {...options}>
      <canvas ref={canvas} {...options} />
    </CanvasContainer>
  );
}

export default QRCode;
