import { useMutation, useQuery } from '@apollo/react-hooks';
import { Select } from 'antd';
import React, { useCallback } from 'react';
import USER_UPDATE from '../../graphql/mutations/userUpdate';
import CLIENT_LIST from '../../graphql/queries/clientList';
import useLogin from '../../hooks/useLogin';

function AdminClientSelect() {
  const { user, logout } = useLogin();
  const { Option } = Select;
  const { loading, error, data } = useQuery(CLIENT_LIST, {
    skip: user.role !== 'superadmin',
  });
  const [updateUser] = useMutation(USER_UPDATE);

  const handleChange = useCallback(async (value) => {
    try {
      const result = await updateUser({
        variables: {
          user_id: user.id,
          payload: {
            client_id: value,
          },
        },
      });
      if (result) {
        logout();
      }
    } catch (e) {
      // deal with error
    }
  }, [user, logout]);

  if (user.role !== 'superadmin') {
    return false;
  }
  if (loading) {
    return 'Loading ...';
  }
  if (error) {
    return 'Error';
  }

  // Directly return the Select component instead of wrapping it in a fragment
  return data && data.clients && (
    <Select
      onChange={handleChange}
      style={{ minWidth: 210, width: 'auto' }}
      placeholder="Please select a Client..."
      value={user.client_id}
    >
      {data.clients.map((client) => (
        <Option key={client.id} value={client.id}>
          {client.company_name}
        </Option>
      ))}
    </Select>
  );
}

export default AdminClientSelect;
