import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import useLogin from '../../hooks/useLogin';
import LoginForm from '../../components/LoginForm';

export default function () {
  const lastLocation = useLastLocation();
  const [firstTime, setFirstTime] = useState(true);
  const { user, login, validate } = useLogin();
  const { t } = useTranslation();

  useEffect(() => {
    async function handleValidate() {
      const token = localStorage.getItem('token');

      if (token) {
        const result = await validate(token);
        if (result) {
          login(result);
        }
      }
    }

    if (firstTime) {
      handleValidate();
      setFirstTime(false);
    }
  }, [login, validate, firstTime]);

  if (user) {
    if (lastLocation && lastLocation.pathname !== '/') {
      return <Redirect to={lastLocation.pathname} />;
    }
    return <Redirect to="/events" />;
  }

  return (
    <div className="p__log-in">
      <div className="log-in-container">
        <div className="grid">
          <div className="column">
            <div className="form">
              <div className="form-header">
                <h1 className="form-header__title text-color-primary">{t('login.title')}</h1>
                <p className="form-header__subtitle text-color-primary fw-bold">{t('login.subtitle')}</p>
              </div>
              <LoginForm />
            </div>
          </div>
          <div className="column log-in__image-container">
            <div className="log-in__bg-image" />
          </div>
        </div>
      </div>
    </div>
  );
}
