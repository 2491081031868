import { Col, Collapse, Form, Input, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import ImageUploadButton from '../../../ImageUpload/ImageUploadButton';
import { RATIO_PORTRAIT } from '../../../ImageUpload/ImageUploadRatio';
import { useModuleFields } from '../../../ModuleForm/ModuleForm.hooks';
import { ModuleType } from '../../../../enums/module.enums';

const Label = styled.span`margin: 0 4px`;

function CustomizationCollapse({ form, module, onChange = () => null }) {
  const { t } = useTranslation();
  const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
  const [image, setImage] = useState(module.image || '');
  const [banner, setBanner] = useState(module.banner_image || '');
  const {
    subtitle_ended = '',
    subtitle_not_started = '',
    subtitle_results = '',
  } = module;
  const moduleFields = useModuleFields(
    getFieldValue('type'),
  );

  const visible = useMemo(() => [
    'image',
    'banner_image',
    'subtitle_ended',
    'subtitle_not_started',
    'subtitle_results',
  ].some((key) => moduleFields[key]), [moduleFields]);

  const forceOpen = getFieldValue('type') === ModuleType.ProductLaunch;

  if (!visible) return null;

  return (
    <Collapse style={{ marginBottom: 16 }} defaultActiveKey={forceOpen ? 'customize' : undefined}>
      <Collapse.Panel key="customize" header="Customize">
        <Row type="flex" justify="start" style={{ marginBottom: 16 }}>
          {moduleFields.banner_image && (
            <Col style={{ marginRight: '2rem', marginBottom: '1rem' }}>
              <Form.Item>
                <ImageUploadButton
                  value={banner}
                  title={t('modules.form.banner.label')}
                  information={t('modules.form.banner.tooltip')}
                  ratio={RATIO_PORTRAIT}
                  onChange={(filename) => {
                    setBanner(filename);
                    setFieldsValue({ banner_image: filename });
                    onChange();
                  }}
                />
              </Form.Item>
              <Form.Item style={{ display: 'none' }}>
                {getFieldDecorator('banner_image', { initialValue: module.banner_image })(<Input type="hidden" />)}
              </Form.Item>
            </Col>
          )}
          {moduleFields.image && (
            <Col style={{ marginRight: '2rem', marginBottom: '1rem' }}>
              <ImageUploadButton
                value={image}
                title={t('modules.form.image.label')}
                information={t('modules.form.image.tooltip')}
                ratio={[21, 9]}
                onChange={(filename) => {
                  setImage(filename);
                  setFieldsValue({ image: filename });
                  onChange();
                }}
              />
            </Col>
          )}
          <Col xl={24} xxl={(moduleFields.image || moduleFields.banner_image) ? 12 : 24}>
            {moduleFields.subtitle_ended && (
              <Form.Item>
                <Label>Ended subtitle:</Label>
                {getFieldDecorator('subtitle_ended', { initialValue: subtitle_ended, onChange })(
                  <Input placeholder={t('modules.form.subtitle_ended.label')} />,
                )}
              </Form.Item>
            )}
            {moduleFields.subtitle_not_started && (
              <Form.Item>
                <Label>Not started subtitle:</Label>
                {getFieldDecorator('subtitle_not_started', { initialValue: subtitle_not_started, onChange })(
                  <Input placeholder={t('modules.form.subtitle_not_started.label')} />,
                )}
              </Form.Item>
            )}
            {moduleFields.subtitle_results && (
              <Form.Item label={t('modules.form.subtitle_results.label')}>
                {getFieldDecorator('subtitle_results', { initialValue: subtitle_results, onChange })(
                  <Input placeholder={t('modules.form.subtitle_results.label')} />,
                )}
              </Form.Item>
            )}
          </Col>
          {getFieldDecorator('image', { initialValue: image })(
            <Input type="hidden" />,
          )}
        </Row>
      </Collapse.Panel>
    </Collapse>
  );
}
export default CustomizationCollapse;
