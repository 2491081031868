import { useQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import CLIENT_MODERATORS from '../../graphql/queries/clientModerators';
import useCreateEvent from '../../hooks/events/create';
import useLogin from '../../hooks/useLogin';
import BreadCrumbs from '../../components/Breadcrumbs';
import EventForm from '../../components/EventForm/EventForm';

function CreateEventPage({ match }) {
  // local state variables
  const { user } = useLogin();
  const [redirect, setRedirect] = useState('');
  const [clientId] = useState(parseInt(match.params.client_id, 10));
  const [apiError, setApiError] = useState('');
  const [moderators, setModerators] = useState([]);
  const [, setFormChanged] = useState(false);
  const breadcrumbs = [
    {
      breadcrumbName: 'Create new Event',
      path: false,
    },
  ];
  // graphql
  const [createEvent] = useCreateEvent(clientId);

  const { data } = useQuery(CLIENT_MODERATORS, {
    variables: {
      client_id: user.client_id,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data && data.client && data.client.moderators) {
      setModerators(data.client.moderators);
    }
  }, [data]);

  const onSubmit = async (payload) => {
    try {
      const result = await createEvent(payload);
      if (result) {
        const { event } = result.data;
        setRedirect(`/events/${event.id}`);
      }
    } catch (err) {
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        setApiError(err.graphQLErrors[0].message);
      } else {
        setApiError('Unknown error occured');
      }
    }
  };

  if (redirect) {
    return (
      <Redirect
        to={{
          pathname: redirect,
          state: { referrer: 'successful-create' },
        }}
      />
    );
  }

  return (
    <div>
      <BreadCrumbs items={breadcrumbs} />
      <EventForm
        type="create"
        onSubmit={onSubmit}
        error={apiError}
        setFormChanged={setFormChanged}
        clientModerators={moderators}
      />
    </div>
  );
}

export default CreateEventPage;
