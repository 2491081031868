import React, { useMemo } from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const itemRender = (route, params, routes) => {
  const last = routes.indexOf(route) === routes.length - 1;
  return last ? (
    <span>{route.breadcrumbName}</span>
  ) : (
    <Link to={route.path}>{route.breadcrumbName}</Link>
  );
};

function BreadCrumbs({ items }) {
  const routes = useMemo(() => [
    {
      breadcrumbName: 'Home',
      path: '/events',
    },
    ...items,
  ], [items]);

  return (
    <nav className="breadcrumbs">
      <Breadcrumb routes={routes} itemRender={itemRender} />
    </nav>
  );
}

export default BreadCrumbs;
