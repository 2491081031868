import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { CSVLink } from 'react-csv';
import GET_MODULE from '../../../graphql/queries/moduleDetail';
import BreadCrumbs from '../../../components/Breadcrumbs';
import MODULE_CLICKS from '../../../graphql/queries/moduleClicks';
import VOTE_DETAILED_RESULTS from '../../../graphql/queries/voteDetailedResults';

function ModuleClickResultsPage({ match }) {
  const module_id = parseInt(match.params.module_id, 10);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [breadcrumbsInitialized, setBreadcrumbsInitialized] = useState(false);
  const [detailedParsedDataLive, setDetailedParsedDataLive] = useState([]);
  const [detailedParsedDataEnded, setDetailedParsedDataEnded] = useState([]);
  const [isLoadingDetails, setIsLoadingDetails] = useState(true);
  const { data: detailedData } = useQuery(VOTE_DETAILED_RESULTS, {
    variables: { module_id },
  });

  const { data: moduleData } = useQuery(GET_MODULE, {
    variables: { module_id },
  });

  const { loading, error, data } = useQuery(MODULE_CLICKS, {
    variables: { module_id },
    pollInterval: 10000,
  });

  useEffect(() => {
    if (moduleData && moduleData.module && detailedData && detailedData.detailedVotes) {
      const parsed = detailedData.detailedVotes.map((vote) => ({
        tracking_id: vote.tracking_id,
        pl_type: vote.pl_type,
        createdAt: vote.createdAt,
      }));

      const parsedLive = parsed.filter((vote) => vote.pl_type === 'LIVE');
      const parsedEnded = parsed.filter((vote) => vote.pl_type === 'ENDED');
      setDetailedParsedDataLive(parsedLive);
      setDetailedParsedDataEnded(parsedEnded);
      setIsLoadingDetails(false);
    }
  }, [moduleData, detailedData]);

  useEffect(() => {
    if (moduleData && moduleData.module) {
      if (!breadcrumbsInitialized) {
        const { module } = moduleData;
        const { session } = module;
        const { event } = session;
        setBreadcrumbs(() => ([
          {
            breadcrumbName: event.name,
            path: `/events/${event.id}`,
          },
          {
            breadcrumbName: session.name,
            path: `/events/${event.id}/sessions/${session.id}`,
          },
          {
            breadcrumbName: module.title,
            path: `/events/${event.id}/sessions/${session.id}/modules/${module.id}`,
          },
          {
            breadcrumbName: 'Clicks',
          },
        ]));
        setBreadcrumbsInitialized(true);
      }
    }
  }, [moduleData, breadcrumbsInitialized]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <div>
      <BreadCrumbs items={breadcrumbs} />
      <header className="page-header">
        <h2>Clicks</h2>
        <div>
          {isLoadingDetails ? 'Loading...' : (

            <>
              <CSVLink
                data={detailedParsedDataLive}
                filename="detailedLiveResults.csv"
                className="btn btn-primary ant-btn ant-btn-primary ant-btn-lg"
                target="_blank"
              >
                Details Live (CSV)
              </CSVLink>
              &nbsp;
              <CSVLink
                data={detailedParsedDataEnded}
                filename="detailedEndedResults.csv"
                className="btn btn-primary ant-btn ant-btn-primary ant-btn-lg"
                target="_blank"
              >
                Details Ended (CSV)
              </CSVLink>
            </>
          )}
        </div>
      </header>
      {data && data.productLaunchResults && (
        <div className="click-total-wrapper">
          <p>Total clicks - LIVE : <span className="clicks-total">{data.productLaunchResults.totalLive}</span></p>
          <p>Total clicks - ENDED : <span className="clicks-total">{data.productLaunchResults.totalEnded}</span></p>
        </div>
      )}
    </div>
  );
}

export default ModuleClickResultsPage;
