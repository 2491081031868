import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useModuleFields } from '../../../ModuleForm/ModuleForm.hooks';

// TODO: Refactor 'header_text' -> 'cta_link'
function ProductLaunchForm({ form, module, onChange }) {
  const { t } = useTranslation();
  const { getFieldDecorator, getFieldValue } = form;
  const moduleFields = useModuleFields(getFieldValue('type'));

  if (!moduleFields.header_text) return null;
  if (!moduleFields.cta_text) return null;

  const rules = [
    { required: true, message: t('modules.form.cta.errors.required') },
  ];

  return (
    <>
      <Row type="flex" gutter={16}>
        <Col span={24}>
          <Form.Item label={t('modules.form.cta.label')}>
            {getFieldDecorator('cta_text', { initialValue: module.cta_text, rules, onChange })(
              <Input placeholder={t('modules.form.cta.placeholder')} />,
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" gutter={16}>
        <Col span={24}>
          <Form.Item label={t('modules.form.cta_link.label')}>
            {getFieldDecorator('header_text', { initialValue: module.header_text, onChange })(
              <Input placeholder={t('modules.form.cta_link.placeholder')} />,
            )}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default ProductLaunchForm;
