import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Checkbox, Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useClientEmojiPacks } from '../../../hooks/emojis';

function ReactionFormFields({ client, module, getFieldDecorator, onChange = () => undefined }) {
  const { t } = useTranslation();
  const [selectedReactions, setSelectedReactions] = useState([]);
  const [hasDisabledOptions, setDisabledOptions] = useState(false);
  const [emojiPacks, loadEmojiPacks] = useClientEmojiPacks(null);
  const emojis = useMemo(() => {
    if (!emojiPacks) return {};

    return emojiPacks.reduce((_emojis, pack) => {
      const packEmojis = pack.emojis.reduce(
        (_packEmojis, emoji) => ({ ..._packEmojis, [emoji.name]: emoji }),
        {},
      );
      return { ..._emojis, ...packEmojis };
    }, {});
  }, [emojiPacks]);

  useEffect(() => {
    if (!client) return;
    loadEmojiPacks(client.id);
  }, [client, loadEmojiPacks]);

  const isOptionDisabled = (optionValue) => {
    if (emojis[optionValue].available === false) {
      return true;
    }
    if (selectedReactions.includes(optionValue)) {
      return false;
    }
    return hasDisabledOptions;
  };

  const handleChange = useCallback((value) => {
    setSelectedReactions(value);
    onChange();
  }, [setSelectedReactions, onChange]);

  useEffect(() => {
    if (!module?.options) return;
    if (selectedReactions?.length > 0) return;
    const selected = module.options.filter((option) => {
      if (!option.published) return false;
      return true;
    }).map((option) => option.image);

    if (!selected.length) return;
    setSelectedReactions(selected);
  }, [module?.options, selectedReactions, setSelectedReactions]);
  useEffect(() => {
    if (selectedReactions.length < 4) {
      setDisabledOptions(false);
    } else {
      setDisabledOptions(true);
    }
  }, [selectedReactions]);

  const totalEmojis = useMemo(() => emojiPacks && (
    emojiPacks.length * emojiPacks.reduce((total, pack) => (
      pack.emojis.length + total
    ), 0)
  ), [emojiPacks]);

  if (!client) return null;

  // TODO: Add localization
  if (!emojiPacks || !totalEmojis) {
    return (
      <Row gutter={16}>
        <Alert
          message={(
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <span>No reactions enabled for client</span>
              <div>
                <Link to={`/clients/${client.id}`} target="_blank">Click here</Link>
                <span>&nbsp;to enable reactions for this client</span>
              </div>
            </div>
          )}
          type="warning"
        />
      </Row>
    );
  }

  return (
    <Form.Item label={t('modules.form.reactions.placeholder')}>
      {getFieldDecorator('options', {
        initialValue: selectedReactions,
        onChange: handleChange,
      })(
        <Checkbox.Group>
          {emojiPacks.map((pack) => (
            <Row key={`emoji-pack-${pack.id}`} guttter={16}>
              {pack.emojis.map((emoji) => (
                <Col key={`emoji-${emoji.id}`} span={6}>
                  <Checkbox
                    value={emoji.name}
                    className="reaction-box"
                    disabled={isOptionDisabled(emoji.name)}
                    style={pack.available ? {} : { opacity: 0.2 }}
                  >
                    <img
                      className="reaction-image"
                      alt={emoji.name}
                      src={`/images/reactions/${emoji.name}.png`}
                    />
                  </Checkbox>
                </Col>
              ))}
            </Row>
          ))}
        </Checkbox.Group>,
      )}
    </Form.Item>
  );
}

export default ReactionFormFields;
