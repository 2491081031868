import React, { useState, useMemo } from 'react';

export const RootContext = React.createContext([{}, () => {}]);

export default function ({ children }) {
  const [state, setState] = useState({});

  const contextValue = useMemo(() => [state, setState], [state, setState]);

  return (
    <RootContext.Provider value={contextValue}>
      {children}
    </RootContext.Provider>
  );
}
