import React from 'react';
import styled from 'styled-components/macro';
import PresenterHead from '../../PresenterHead';

const HeadingWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`;
const HeadingWrapperWithImage = styled(HeadingWrapper)`
  justify-content: flex-start;
  margin-bottom: 12px;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const VoteImage = styled.img`
  --image-height: 200px;
  height: var(--image-height);
  width: calc(var(--image-height) * calc(16 / 9));
  object-fit: cover;
  border: 1px solid #cccccc;
  margin-top: 2rem;
  
  @media (max-width: 1280px) {
    --image-height: 160px;
  }

  @media (max-width: 900px) {
    display: none;
  }
`;

const PresenterHeadWithImage = styled(PresenterHead.Container)`
  align-items: flex-start;
  margin-left: 24px;
  margin-top: 2rem;
  
  @media (max-width: 1280px) {
    font-size: 1rem;
  }
`;

function WordCloudHeading({ image, title, subtitle }) {
  if (image) {
    return (
      <HeadingWrapperWithImage>
        <VoteImage
          src={`${process.env.REACT_APP_STATIC_URL}/${image}`}
          alt={title}
        />
        <PresenterHeadWithImage>
          <PresenterHead.Title>{title}</PresenterHead.Title>
          {subtitle && (
            <PresenterHead.Subtitle>{subtitle}</PresenterHead.Subtitle>
          )}
        </PresenterHeadWithImage>
      </HeadingWrapperWithImage>
    );
  }

  return (
    <PresenterHead title={title} subtitle={subtitle} />
  );
}

export default WordCloudHeading;
