import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@apollo/react-hooks';
import { message } from 'antd';
import GET_PUBLIC_EVENT from '../../graphql/queries/publicEvent';
import EventForm from '../../components/EventForm/EventForm';
import SessionsList from '../../components/SessionsList';
import BreadCrumbs from '../../components/Breadcrumbs';

import useUpdateEvent from '../../hooks/events/update';
import useLogin from '../../hooks/useLogin';
import { EventProvider } from '../../providers/EventProvider';
import { useMappedParams } from '../../hooks/utils';
import { useEvent } from '../../hooks/providers/events';

function UpdateEventPage() {
  const { eventId } = useMappedParams({
    eventId: (params) => +params.event_id,
  });
  return (
    <EventProvider eventId={eventId} refetch={false}>
      <UpdateEventWrapper />
    </EventProvider>
  );
}

function UpdateEventWrapper() {
  // we need user object here
  const { isModerator } = useLogin();
  const { eventId } = useMappedParams({
    eventId: (params) => +params.event_id,
  });

  // local state variables
  const [apiError, setApiError] = useState('');
  const [formChanged, setFormChanged] = useState(false);

  // graphql
  const [event, { load: loadEvent, error }] = useEvent();
  // Breadcrumbs
  const breadcrumbs = useMemo(() => {
    if (!event) return [];
    return [
      {
        breadcrumbName: event.name,
        path: false,
      },
    ];
  }, [event]);

  const [updateEvent] = useUpdateEvent();

  // TODO
  const { data: publicEvent } = useQuery(GET_PUBLIC_EVENT, {
    fetchPolicy: 'network-only',
    variables: { event_id: eventId },
  });

  const onSubmit = async (payload) => {
    try {
      const result = await updateEvent(eventId, payload);
      if (result) {
        const { name } = result.data.event;
        message.success(`Successfully updated event: ${name}`);
        loadEvent();
      }
    } catch (err) {
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        // setApiError(err.graphQLErrors[0].message);
        console.error(err, err.graphQLErrors);
      } else {
        setApiError('Unknown error occurred');
      }
    }
  };
  let eventCreated = false;
  const location = useLocation();
  if (location.state && location.state.referrer === 'successful-create') {
    if (location.state.referrer === 'successful-create') {
      eventCreated = true;
    }
  }

  if (error) return `Error! ${error.message}`;
  if (!event) return 'Loading...';
  return (
    <div>
      <BreadCrumbs items={breadcrumbs} />
      {!isModerator && (
        <EventForm
          type="update"
          onSubmit={onSubmit}
          event={event}
          publicEvent={publicEvent}
          isModerator={isModerator}
          error={apiError}
          eventCreated={eventCreated}
          setFormChanged={setFormChanged}
        />
      )}
      <SessionsList formChanged={formChanged} />
    </div>
  );
}

export default UpdateEventPage;
