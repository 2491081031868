import { useQuery } from '@apollo/react-hooks';
import { Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import GET_METER_VOTE_RESULTS from '../../../graphql/queries/getMeterVoteResults';
import GET_MODULE from '../../../graphql/queries/moduleDetail';
import BreadCrumbs from '../../../components/Breadcrumbs';

function ModuleMeterResultsPage({ match }) {
  const module_id = parseInt(match.params.module_id, 10);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [breadcrumbsInitialized, setBreadcrumbsInitialized] = useState(false);

  const { data: moduleData } = useQuery(GET_MODULE, {
    variables: { module_id },
  });
  const { data: resultData } = useQuery(GET_METER_VOTE_RESULTS, {
    variables: { module_id },
    pollInterval: 10000,
  });

  useEffect(() => {
    if (moduleData && moduleData.module) {
      if (!breadcrumbsInitialized) {
        const { module } = moduleData;
        const { session } = module;
        const { event } = session;
        setBreadcrumbs(() => ([
          {
            breadcrumbName: event.name,
            path: `/events/${event.id}`,
          },
          {
            breadcrumbName: session.name,
            path: `/events/${event.id}/sessions/${session.id}`,
          },
          {
            breadcrumbName: module.title,
            path: `/events/${event.id}/sessions/${session.id}/modules/${module.id}`,
          },
          {
            breadcrumbName: 'Results',
          },
        ]));
        setBreadcrumbsInitialized(true);
      }
    }
  }, [moduleData, breadcrumbsInitialized]);

  const table = useMemo(() => {
    if (!resultData) return null;
    if (!resultData.meterVoteResults) return null;
    const { result, total } = resultData.meterVoteResults;

    const data = [
      { key: 'average', label: 'Average', value: `${result}%` },
      { key: 'total', label: 'Total', value: total },
    ];

    const columns = [{
      title: 'Metric',
      dataIndex: 'label',
    }, {
      title: 'Value',
      dataIndex: 'value',
    }];

    return { columns, data };
  }, [resultData]);

  if (!table) return null;

  return (
    <div>
      <BreadCrumbs items={breadcrumbs} />
      <header className="page-header">
        <h2>Results</h2>
      </header>

      {table && (
        <Table
          columns={table.columns}
          dataSource={table.data}
          pagination={false}
        />
      )}
    </div>
  );
}

export default ModuleMeterResultsPage;
