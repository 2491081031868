import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { ModuleType, ModuleTypeNames } from '../../../enums/module.enums';
import GET_ALL_COMMENTS from '../../../graphql/queries/getAllComments';
import GET_MODULE from '../../../graphql/queries/moduleDetail';
import BreadCrumbs from '../../../components/Breadcrumbs';
import AddComment from '../../../components/Module/Form/AddComment';
import NewComment from '../../../components/Module/Comments/NewComment';
import { useEventColors } from '../../../hooks/useEventColors';

// TODO: Make "Thread" a generic module that both the Comments and Q&A modules can use
function ModuleCommentsPage({ match }) {
  const module_id = parseInt(match.params.module_id, 10);
  const { primary: moderatorColor } = useEventColors();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [breadcrumbsInitialized, setBreadcrumbsInitialized] = useState(false);
  const [replyingComment, setReplyingComment] = useState(null);
  const { data: moduleData, loading: moduleLoading } = useQuery(GET_MODULE, {
    variables: {
      module_id,
    },
  });

  useEffect(() => {
    if (moduleData && moduleData.module) {
      if (!breadcrumbsInitialized) {
        const { module } = moduleData;
        const { session } = module;
        const { event } = session;
        setBreadcrumbs(() => ([
          {
            breadcrumbName: event.name,
            path: `/events/${event.id}`,
          },
          {
            breadcrumbName: session.name,
            path: `/events/${event.id}/sessions/${session.id}`,
          },
          {
            breadcrumbName: module.title,
            path: `/events/${event.id}/sessions/${session.id}/modules/${module.id}`,
          },
          {
            breadcrumbName: moduleData.module.type === ModuleType.Comments
              ? ModuleTypeNames.Comments
              : ModuleTypeNames.QuestionsAndAnswers,
          },
        ]));
        setBreadcrumbsInitialized(true);
      }
    }
  }, [moduleData, breadcrumbsInitialized]);

  const { loading, data } = useQuery(GET_ALL_COMMENTS, {
    variables: {
      module_id,
    },
    pollInterval: 10000,
  });
  const sortedComments = useMemo(() => {
    if (loading || !data) return [];

    const { comments } = data.allComments;
    const [parents, children] = _.partition(comments, (comment) => comment.parent_id === 0);

    return parents.map((comment) => {
      const replies = children.filter((reply) => reply.parent_id === comment.id);
      return { ...comment, replies };
    }, []);
  }, [loading, data]);

  const handleReply = useCallback((comment) => {
    if (replyingComment) {
      setReplyingComment(null);
      setTimeout(() => {
        setReplyingComment(comment);
      }, 300);
      return;
    }
    setReplyingComment(comment);
  }, [replyingComment]);

  const csvComments = useMemo(() => {
    if (!data) return [];
    if (!data.allComments) return [];

    const random = Math.floor(Math.random() * 69420);
    return data.allComments.comments.map((comment) => ({
      id: random + comment.id,
      date: comment.createdAt,
      tracking: comment.tracking_id,
      username: comment.author,
      moderator: comment.is_moderated ? 'Y' : 'N',
      hidden: comment.is_hidden ? 'Y' : 'N',
      comment: comment.text,
      total_upvotes: comment.total_upvotes,
      reply_to: comment.parent_id === 0 ? 'N' : random + comment.parent_id,
    })).reverse();
  }, [data]);

  const csvFilename = useMemo(() => {
    if (!moduleData) return `${ModuleType.Comments}.csv`;
    if (!moduleData.module) return `${ModuleType.Comments}.csv`;

    const { id, type } = moduleData.module;
    return `${type}-${id}.csv`;
  }, [moduleData]);

  if (loading || moduleLoading) {
    return 'Loading...';
  }

  return (
    <>
      <BreadCrumbs items={breadcrumbs} />
      <AddComment
        type={moduleData.module.type}
        module_id={module_id}
        moderatorColor={moderatorColor}
        replyingComment={replyingComment}
        onSubmit={() => setReplyingComment(undefined)}
      />
      <header className="page-header">
        <h2>{moduleData.module.type === ModuleType.Comments ? 'Comments' : 'Questions'}</h2>
        <CSVLink
          data={csvComments}
          filename={csvFilename}
          className="btn btn-primary ant-btn ant-btn-primary ant-btn-lg"
          target="_blank"
        >
          {moduleData.module.type === ModuleType.Comments ? 'Comments' : 'Questions'} (CSV)
        </CSVLink>
      </header>
      <div className="comments-wrapper">
        {sortedComments.map((comment) => (
          <NewComment
            key={comment.id}
            comment={comment}
            moderatorColor={moderatorColor}
            onReply={handleReply}
            showReplies={moduleData.module.type === ModuleType.Comments}
          />
        ))}
      </div>
    </>
  );
}

export default ModuleCommentsPage;
