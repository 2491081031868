import moment from 'moment';
import { useMutation } from '@apollo/react-hooks';
import { Button, Icon, message, Modal, Popconfirm, Select, Switch, Tooltip } from 'antd';
import React, { useCallback, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { ModuleStatus } from '../../enums/module.enums';
import DELETE_MODULE from '../../graphql/mutations/deleteModule';
import MODULE_DUPLICATE from '../../graphql/mutations/duplicateModule';

import UPDATE_MODULE from '../../graphql/mutations/updateModule';
import useLogin from '../../hooks/useLogin';
import ModuleResults from './ModuleResults';
import ModuleTypeTag from './ModuleTypeTag';
import { useSession, useSessionModules } from '../../hooks/providers/sessions';
import { useEvent } from '../../hooks/providers/events';

export function useDuplicateModule(callback = () => undefined) {
  const [session] = useSession();
  const [duplicateModule] = useMutation(MODULE_DUPLICATE, { refetchQueries: ['modulesBySession'] });

  return useCallback(async (module_id, module_name) => {
    const handleOk = async () => {
      try {
        const name = `${module_name}-COPY`;
        await duplicateModule({
          variables: {
            payload: {
              module_id,
              title: name,
              // TODO: Refactor episode_id -> session_id
              episode_id: session.id,
            },
          },
        });
        message.success('Module duplicated');
        callback();
      } catch (e) {
        message.error('Something went wrong');
      }
    };

    Modal.confirm({
      title: 'Are you sure you want to duplicate this module?',
      content: 'This copies the module and its contents by adding "-COPY" to the end of the module name',
      onOk: handleOk,
    });
  }, [session, duplicateModule]);
}

export function useDeleteModule() {
  const [deleteModule] = useMutation(DELETE_MODULE, { refetchQueries: ['modulesBySession'] });

  return useCallback(async (event, module_id) => {
    event.stopPropagation();
    try {
      await deleteModule({
        variables: { module_id },
      });
      message.success('Module deleted');
    } catch (e) {
      message.error(`Something went wrong: ${e.message}`);
    }
  }, [deleteModule]);
}

export function useSetModulePublished() {
  const [updateModule] = useMutation(UPDATE_MODULE, { refetchQueries: ['modulesBySession'] });
  return useCallback(async (module_id, published = true) => {
    try {
      await updateModule({
        variables: { module_id, payload: { published } },
      });

      message.destroy();
      if (published) message.success('Module published');
      else message.success('Module unpublished');
    } catch (e) {
      console.error(e.message, e, 'module publising error');
      // message.error('Something went wrong');
    }
  }, [module, updateModule]);
}

export function useSetModuleStatus() {
  const [updateModule] = useMutation(UPDATE_MODULE, { refetchQueries: ['modulesBySession'] });
  return useCallback(async (module, status) => {
    try {
      await updateModule({
        variables: {
          module_id: module.id,
          payload: { status },
        },
      });
      message.success('Module updated');
    } catch (e) {
      message.error('Something went wrong');
    }
  }, [updateModule]);
}

export function useSetModuleWeight(modules, onChange = () => {}) {
  const [updateModule] = useMutation(UPDATE_MODULE, { refetchQueries: ['modulesBySession'] });

  const normalizeModuleWeights = useCallback(async () => {
    const promises = modules.map((module, index) => {
      if (module.weight === index + 1) return Promise.resolve();
      return updateModule({
        variables: {
          module_id: module.id,
          payload: { weight: index + 1 },
        },
      });
    });

    return Promise.all(promises);
  }, [modules, updateModule]);

  // TODO: This should completely be handled by the backend.
  //  Assume old weight O
  //  Assume new weight: N
  //  For every module M below O and above N:
  //    Increment weight of M
  //  For every module M below N and below O:
  //    Decrement weight of M
  return useCallback(async (moduleId, newWeight) => {
    const { weight: oldWeight } = modules.find(({ id }) => id === moduleId);

    if (oldWeight === newWeight) return;
    if (newWeight <= 0) return;
    if (newWeight > modules.length) return;
    // skip if trying to move more than two spots at a time
    if (Math.abs(newWeight - oldWeight) > 1) return;

    await normalizeModuleWeights();

    const modulesBelow = modules.reduce((below, module) => {
      // Skip the module itself
      if (module.id === moduleId) return below;
      // Skip anything that is above the new weight
      if (module.weight > newWeight) return below;
      return [...below, module];
    }, []);

    const promisesBelow = modulesBelow
      // Just in case, sort all the nodes by weight
      .sort((a, b) => a.weight - b.weight)
      .map((module, index) => {
        if (newWeight < oldWeight && index + 1 === newWeight) {
          return updateModule({
            variables: {
              module_id: module.id,
              payload: { weight: oldWeight },
            },
          });
        }
        return updateModule({
          variables: {
            module_id: module.id,
            payload: { weight: index + 1 },
          },
        });
      });

    await Promise.all(promisesBelow);
    await updateModule({
      variables: {
        module_id: moduleId,
        payload: { weight: newWeight },
      },
    });

    onChange();
  }, [modules, updateModule]);
}

export function useModulesTable(onChange = () => undefined) {
  const { user, isModerator } = useLogin();
  const [event] = useEvent();
  const [session] = useSession();
  const [modules, { reloadModules }] = useSessionModules(false);

  const deleteModule = useDeleteModule();
  const setModuleWeight = useSetModuleWeight(modules, reloadModules);
  const setModuleStatus = useSetModuleStatus();
  const setModulePublished = useSetModulePublished();
  const duplicateModule = useDuplicateModule();

  // TODO: Clean duplicate code
  const sessionPath = useMemo(
    () => `/events/${event?.id}/sessions/${session?.id}`,
    [event, session],
  );

  const columns = useMemo(() => [
    {
      title: isModerator ? '' : 'Order',
      dataIndex: 'weight',
      width: 100,
      align: 'center',
      render: (text, record) => {
        if (isModerator) {
          return '';
        }

        return (
          <>
            <Button size="small" type="link" onClick={() => setModuleWeight(record.id, record.weight - 1)}>
              <Icon type="up" />
            </Button>
            <Button size="small" type="link" onClick={() => setModuleWeight(record.id, record.weight + 1)}>
              <Icon type="down" />
            </Button>
          </>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'title',
      render: (text, { id, subtitle, subtitle_not_started, subtitle_ended, type, subtype, status }) => {
        let currentSubtitle = subtitle;
        if (status === ModuleStatus.Ended) {
          currentSubtitle = subtitle_ended;
        } else if (status === ModuleStatus.NotStarted) {
          currentSubtitle = subtitle_not_started;
        }
        return (
          <NavLink
            to={`${sessionPath}/modules/${id}`}
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', color: 'unset' }}
          >
            <div style={{ display: 'flex' }}>
              <span style={{ marginRight: 8 }}>{text}</span>
              <ModuleTypeTag type={type} subtype={subtype} />
            </div>
            {currentSubtitle && (
              <small>{currentSubtitle}</small>
            )}
          </NavLink>
        );
      },
    },
    {
      title: 'Module opens',
      dataIndex: 'schedule_start',
      width: 180,
      render: (value) => {
        if (!value) return '—';
        return moment.utc(value).local().format('YYYY-MM-DD HH:mm');
      },
    },
    {
      title: isModerator ? '' : 'Status',
      dataIndex: 'status',
      key: 'action_status',
      width: 150,
      render: (text, record) => {
        if (isModerator) return '';

        return (
          <Select
            defaultValue={record.status}
            onChange={async (value) => {
              await setModuleStatus(record, value);
              onChange();
            }}
            style={{ width: '100%' }}
          >
            <Select.Option value={ModuleStatus.NotStarted}>Not started</Select.Option>
            <Select.Option value={ModuleStatus.Live}>Live</Select.Option>
            <Select.Option value={ModuleStatus.Ended}>Ended</Select.Option>
          </Select>
        );
      },
    },
    {
      title: isModerator ? '' : 'Published',
      dataIndex: 'published',
      key: 'action_isPublished',
      width: 100,
      align: 'center',
      onCell: () => ({
        onClick: (e) => e.stopPropagation(),
      }),
      render: (text, record) => {
        if (isModerator) return '';
        return (
          <Switch
            onChange={async () => {
              await setModulePublished(record.id, !record.published);
              onChange();
            }}
            defaultChecked={record.published}
          />
        );
      },
    },
    {
      title: '',
      key: 'actions',
      width: 150,
      align: 'center',
      render: (text, record) => {
        if (isModerator) return null;
        return (
          <>
            <span style={{ padding: '0 7px' }}>
              <ModuleResults id={record.id} type={record.type} user={user} />
            </span>
            <Tooltip title="Duplicates this Module">
              <Button
                type="link"
                size="small"
                onClick={async () => {
                  await duplicateModule(record.id, record.title);
                  onChange();
                }}
              >
                <Icon type="copy" />
              </Button>
            </Tooltip>
            <Popconfirm
              title="Are you sure you want to delete this module?"
              onConfirm={(e) => deleteModule(e, record.id)}
            >
              <Button type="link" size="small">
                <Icon type="delete" />
              </Button>
            </Popconfirm>
          </>
        );
      },
    },
  ], [
    user, isModerator,
    duplicateModule, deleteModule,
    setModuleWeight, setModuleStatus, setModulePublished,
  ]);

  const dataSource = useMemo(() => {
    if (!modules) return [];

    return modules.map((module, index) => ({
      key: index + 1,
      weight: module.weight,
      title: module.title,
      subtitle: module.subtitle,
      subtitle_not_started: module.subtitle_not_started,
      subtitle_ended: module.subtitle_ended,
      id: module.id,
      type: module.type,
      subtype: module.subtype,
      published: module.published,
      status: module.status,
      schedule_start: module.schedule_start,
    }));
  }, [modules]);

  return { columns, dataSource };
}
