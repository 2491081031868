import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { message } from 'antd';
import ClientForm from '../../components/ClientForm';
import CLIENT_DETAIL from '../../graphql/queries/clientDetail';
import CLIENT_UPDATE from '../../graphql/mutations/clientUpdate';
import { UPDATE_CLIENT_EMOJI_PACKS } from '../../graphql/queries/emojis/clientEmojis';

function UpdateClientPage({ match }) {
  // local state variables

  const client_id = parseInt(match.params.client_id, 10);

  // graphql
  const { loading, error, data } = useQuery(CLIENT_DETAIL, {
    variables: {
      client_id,
    },
  });

  const [updateClient] = useMutation(CLIENT_UPDATE, {
    refetchQueries: ['clientList'],
  });
  const [updateEmojiPacks] = useMutation(UPDATE_CLIENT_EMOJI_PACKS);

  // if a client id has been set, redirect
  const onSubmit = async ({ emojiPacks, ...payload }) => {
    try {
      const result = await updateClient({
        variables: {
          client_id,
          payload,
        },
      });
      await updateEmojiPacks({
        variables: {
          payload: { client_id, emojiPacks },
        },
      });
      if (result) {
        message.success(`Successfully updated client: ${result.data.updateClient.company_name}`);
      }
    } catch (exception) {
      message.error('Something went wrong');
    }
  };

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;
  if (!data) return null;

  return (
    <div>
      <h1>Update client</h1>
      <ClientForm onSubmit={onSubmit} client={data.client} />
    </div>
  );
}

export default UpdateClientPage;
