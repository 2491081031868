import React from 'react';
import { Icon, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

const wrapperMaxWidth = ({ compact }) => {
  if (compact) return '500px';
  return 'unset';
};
export const TooltipContentWrapper = styled.div`
  max-width: ${wrapperMaxWidth};
  white-space: pre-wrap;
`;
function LocalizedTooltipBubble({
  contentKey,
  color = 'black',
  placement = 'topRight',
  compact = true,
  iconStyle = {},
}) {
  const { t } = useTranslation();
  return (
    <Popover
      trigger="hover"
      placement={placement}
      content={(
        <TooltipContentWrapper compact={compact}>
          {!!contentKey && t(contentKey)}
        </TooltipContentWrapper>
      )}
    >
      <Icon
        type="question-circle"
        style={{ marginLeft: 'auto', color, ...iconStyle }}
      />
    </Popover>
  );
}

export default LocalizedTooltipBubble;
