import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import CLIENT_ADD from '../../graphql/mutations/clientAdd';
import { UPDATE_CLIENT_EMOJI_PACKS } from '../../graphql/queries/emojis/clientEmojis';
import ClientForm from '../../components/ClientForm';

function CreateClientPage() {
  // local state variables
  const [redirect, setRedirect] = useState('');

  // graphql
  const [addClient] = useMutation(CLIENT_ADD, {
    refetchQueries: ['clientList'],
  });
  const [updateEmojiPacks] = useMutation(UPDATE_CLIENT_EMOJI_PACKS);

  // if a client id has been set, redirect
  const onSubmit = async ({ emojiPacks, ...payload }) => {
    // eslint-disable-next-line
    payload.voter_details = '[]';
    const result = await addClient({
      variables: {
        payload,
      },
    });

    if (result) {
      const { id: clientId } = result.data.createClient;
      await updateEmojiPacks({
        variables: {
          payload: { client_id: clientId, emojiPacks },
        },
      });
      setRedirect(`/clients/${clientId}`);
    }
  };

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <div>
      <h1>Create client</h1>
      <ClientForm onSubmit={onSubmit} />
    </div>
  );
}

export default CreateClientPage;
