import React, { useCallback, useMemo } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import useKeyboardListener from '../../hooks/utils/useKeyboardListener';
import { useAdjacentModules, useModule, useModulePath, useSessionModules } from '../../hooks/providers';
import { isModulePresentable } from '../../enums/module.enums';
import { useEventColors } from '../../hooks/useEventColors';

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  gap: 0 16px;
  background-color: ${({ $color }) => ($color || 'white')};
  z-index: 1;
`;

const FooterDotElement = styled(NavLink)`
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #acacac;
`;

function useColorsStyle() {
  const colors = useEventColors();

  return useMemo(() => {
    if (!colors) return {};
    const { primary, secondary } = colors;
    return {
      '--color-primary': `#${primary}`,
      '--color-secondary': `#${secondary}`,
    };
  }, [colors]);
}

function FooterDot({ module }) {
  const style = useColorsStyle();
  const modulePath = useModulePath();

  const location = modulePath(module);
  if (!location) return null;

  return (
    <FooterDotElement
      to={location}
      activeStyle={{
        backgroundColor: style['--color-primary'] ? style['--color-primary'] : 'white',
      }}
    />
  );
}

function usePresentableModules() {
  const [modules] = useSessionModules();

  return useMemo(
    () => modules.filter(isModulePresentable),
    [modules],
  );
}

function PresenterFooter() {
  const history = useHistory();
  const style = useColorsStyle();
  const modulePath = useModulePath();

  const modules = usePresentableModules();
  const { previous, next } = useAdjacentModules();
  const [currentModule] = useModule();

  useKeyboardListener(
    useCallback((event) => {
      const isHandled = ['ArrowRight', 'ArrowLeft', 'f'].includes(event.key);
      if (!isHandled) return;
      event.preventDefault();

      switch (event.code) {
        case 'ArrowLeft': {
          if (previous) history.push(modulePath(previous));
          break;
        }
        case 'ArrowRight': {
          if (next) history.push(modulePath(next));
          break;
        }
        default:
          break;
      }
    }, [modulePath, history, previous, next]),
  );

  if (!currentModule) return null;

  return (
    <Footer $color={style['--color-secondary']}>
      {modules.map((module) => (
        <FooterDot key={module.id} module={module} />
      ))}
    </Footer>
  );
}

export default PresenterFooter;
