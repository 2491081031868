import React, { useMemo } from 'react';
import styled from 'styled-components';
import VOTE_RESULTS from '../../../../graphql/queries/voteResults';

import { useModuleUpdateQuery } from '../../hooks/useModuleUpdates';
import { useEventColors } from '../../../../hooks/useEventColors';

import PresenterHead from '../../PresenterHead';
import VoteResults from './VoteResults';

const VotePresenterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

function VotePresenter({ module }) {
  const colors = useEventColors();
  const [data, { loading }] = useModuleUpdateQuery(
    module.id,
    VOTE_RESULTS,
    useMemo(
      () => ({ module_id: module.id }),
      [module.id],
    ),
  );

  const votes = useMemo(() => {
    if (loading) return [];
    if (!data) return [];
    if (!data.voteResults) return [];
    if (!data.voteResults.options) return [];

    return data.voteResults.options
      .sort((a, b) => a.id - b.id)
      .map(({ option, ...item }) => {
        const { image } = module.options
          .find((opt) => opt.id === item.id) ?? {};
        const imageUrl = image && `${process.env.REACT_APP_STATIC_URL}/${image}`;
        return {
          ...item,
          option: { ...option, image: imageUrl },
        };
      });
  }, [loading, data]);

  const totalVotes = useMemo(() => {
    if (loading) return 0;
    if (!data) return 0;
    if (!data.voteResults) return 0;
    return data.voteResults.totalCount ?? 0;
  }, [loading, data]);

  return (
    <VotePresenterContainer
      primaryColor={colors.primary}
      secondaryColor={colors.secondary}
    >
      <PresenterHead
        image={module.image}
        title={module.title}
        subtitle={module.subtitle}
      />

      <VoteResults
        votes={votes}
        total={totalVotes}
        primaryColor={colors.primary}
        secondaryColor={colors.secondary}
      />
    </VotePresenterContainer>
  );
}

export default VotePresenter;
