import React from 'react';
import { NavLink } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import USER_LIST from '../../graphql/queries/userList';

function UserDetail({ match }) {
  const user_id = parseInt(match.params.user_id, 10);

  // graphql
  const { loading, error, data } = useQuery(USER_LIST, {
    variables: { user_id },
  });

  if (loading) return 'Loading...';
  if (error) return `Error! ${error.message}`;

  const { first_name, last_name, email, client, role_id, active } = data.users[0];

  // TODO: zero styling done, needs to be done
  return (
    <div>
      <h1>{`${first_name} ${last_name}`}</h1>
      <div>
        <span>Email</span>
        <span>{email}</span>
      </div>
      <div>
        <span>Client</span>
        <span>{client && client.company_name}</span>
      </div>
      <div>
        <span>Role</span>
        <span>{role_id}</span>
      </div>
      <div>
        <span>Active</span>
        <span>{active ? 'Yes' : 'No'}</span>
      </div>
      <NavLink to={`/users/${user_id}`}>
        Update this user
      </NavLink>
    </div>
  );
}

export default UserDetail;
