import { Icon, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { ModuleType } from '../../enums/module.enums';
import { useEvent } from '../../hooks/providers/events';
import { useSession } from '../../hooks/providers/sessions';
import { RouteBuilders } from '../../routes';

function ModuleResults({ id, type, user }) {
  const [event] = useEvent();
  const [session] = useSession();

  const modulePath = useMemo(() => {
    if (!event) return null;
    if (!session) return null;
    return RouteBuilders.ofModule({
      eventId: event.id,
      sessionId: session.id,
      moduleId: id,
    });
  }, [event, session, id]);

  if (!modulePath) return null;

  switch (type) {
    case ModuleType.Vote:
      if (user.role !== 'editor') {
        return (
          <Tooltip title="See the vote results">
            <NavLink to={`${modulePath}/vote-results`}>
              <Icon type="table" />
            </NavLink>
          </Tooltip>
        );
      }

      return null;
    case ModuleType.ProductLaunch:
      if (user.role !== 'editor') {
        return (
          <Tooltip title="See the total clicks">
            <NavLink to={`${modulePath}/click-results`}>
              <Icon type="number" />
            </NavLink>
          </Tooltip>
        );
      }

      return null;
    case ModuleType.WordCloud:
      return (
        <Tooltip title="See the word cloud results">
          <NavLink to={`${modulePath}/vote-results`}>
            <Icon type="cloud" />
          </NavLink>
        </Tooltip>
      );
    case ModuleType.Reaction:
      return (
        <Tooltip title="See the reaction results">
          <NavLink to={`${modulePath}/vote-results`}>
            <Icon type="table" />
          </NavLink>
        </Tooltip>
      );
    case ModuleType.Comments:
      return (
        <Tooltip title="See comments">
          <NavLink to={`${modulePath}/comments`}>
            <Icon type="message" />
          </NavLink>
        </Tooltip>
      );
    case ModuleType.QuestionsAndAnswers:
      return (
        <Tooltip title="See questions and answers">
          <NavLink to={`${modulePath}/comments`}>
            <Icon type="question" />
          </NavLink>
        </Tooltip>
      );
    case ModuleType.Moodmeter:
      return (
        <Tooltip title="See questions and answers">
          <NavLink to={`${modulePath}/meter-results`}>
            <Icon type="number" />
          </NavLink>
        </Tooltip>
      );
    default:
      return null;
  }
}

export default ModuleResults;
