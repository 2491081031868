import React from 'react';
import styled from 'styled-components/macro';

import { useEvent } from '../../hooks/providers/events';

const CHeading = styled.span`
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;

  @media (min-width: 1024px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const CLink = styled.a`
  color: inherit;
  font-size: inherit;
  border-bottom: 1px solid #808080;

  &:hover {
    color: inherit;
  }
`;

function PresenterWatermark() {
  const [event] = useEvent();

  if (!event) return null;
  if (event.client.whitelabel) return null;

  return (
    <CHeading>
      <span>Powered by&nbsp;</span>
      <CLink target="_blank" href="https://votemo.eu">Votemo</CLink>
    </CHeading>
  );
}

export default PresenterWatermark;
