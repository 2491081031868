import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import shortid from 'shortid';
import { Avatar, Button, Form, Icon, Input, message, Table, Tooltip } from 'antd';

import { useTranslation } from 'react-i18next';
import OPTION_UPDATE from '../../graphql/mutations/optionUpdate';
import OPTION_ADD from '../../graphql/mutations/optionAdd';
import OPTION_DELETE from '../../graphql/mutations/optionDelete';
import { RATIO_SQUARE } from '../ImageUpload/ImageUploadRatio';
import ImageUploadButton from '../ImageUpload/ImageUploadButton';
import { useModule, useModuleOptions } from '../../hooks/providers/modules';

const EditableOptionContext = React.createContext({});
const staticUrl = process.env.REACT_APP_STATIC_URL;

function EditableCell(props) {
  const { t } = useTranslation();
  const {
    editing,
    dataIndex,
    children,
    record,
    title,
    ...restProps
  } = props;
  const form = useContext(EditableOptionContext);
  const { getFieldDecorator } = form;

  const getInput = () => {
    if (dataIndex !== 'image') {
      return <Input />;
    }
    return (
      <ImageUploadButton
        value={record.image}
        title={t('options.labels.image')}
        information={t('options.tooltips.image')}
        ratio={RATIO_SQUARE}
        onChange={(filename) => form.setFieldsValue({ image: filename })}
      />
    );
  };
  if (!editing) {
    return <td {...restProps}>{children}</td>;
  }
  return (
    <td {...restProps}>
      {dataIndex !== 'image' ? (
        <Form.Item>
          {getFieldDecorator(dataIndex, {
            rules: [
              {
                required: dataIndex === 'title',
                message: `Please Input ${title}!`,
              },
            ],
            initialValue: record[dataIndex],
          })(getInput())}
        </Form.Item>
      ) : (
        <>
          <Form.Item>
            {getFieldDecorator(dataIndex, {
              initialValue: record[dataIndex],
            })(<Input type="hidden" />)}
          </Form.Item>
          {getInput()}
        </>
      )}
    </td>
  );
}

function ListOptions(params) {
  const { t } = useTranslation();
  const [module, { load: loadModule }] = useModule();
  const moduleOptions = useModuleOptions();
  const { form, moduleSubType, subTypeChanged } = params;
  const [editDisabled, setEditDisabled] = useState(false);
  const [orderChanged, setOrderChanged] = useState(false);
  const [normalized, setNormalized] = useState(false);
  const [editRow, setEditRow] = useState(false);
  const [newOption, setNewOption] = useState(false);
  const [updateOption] = useMutation(OPTION_UPDATE);
  const [addOption] = useMutation(OPTION_ADD);
  const [deleteOption] = useMutation(OPTION_DELETE);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);

  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (!moduleOptions) return;
    setOptions(moduleOptions);
  }, [moduleOptions, setOptions]);

  const components = {
    body: {
      cell: EditableCell,
    },
  };
  const handleDeleteClick = async (record) => {
    const { id: option_id } = record;
    try {
      const result = await deleteOption({
        variables: { option_id },
      });
      if (result) {
        message.success('Successfully deleted option');
        loadModule();
      }
    } catch (e) {
      message.error('Something went wrong');
    }
  };
  const handleEditClick = (option) => {
    setEditRow(option.key);
  };
  const createOption = () => {
    setNewOption(true);
  };
  const handleOrderUp = async (record) => {
    const { id: option_id, weight } = record;
    if (weight === 1) {
      return;
    }
    const newWeight = weight - 1;
    setOrderChanged(true);
    await updateOption({
      variables: {
        option_id,
        payload: { weight: newWeight },
      },
    });
    options.map(async (option) => {
      if (option.weight === newWeight) {
        await updateOption({
          variables: {
            option_id: option.id,
            payload: { weight },
          },
        });
      }
    });
    loadModule();
  };
  const handleOrderDown = async (record) => {
    const { id: option_id, weight } = record;
    if (weight === options.length) {
      return;
    }
    const newWeight = weight + 1;
    setOrderChanged(true);
    await updateOption({
      variables: {
        option_id,
        payload: { weight: newWeight },
      },
    });
    options.map(async (option) => {
      if (option.weight === newWeight) {
        await updateOption({
          variables: {
            option_id: option.id,
            payload: { weight },
          },
        });
      }
    });
    loadModule();
  };

  const handleCreate = async (payload) => {
    try {
      const result = await addOption({
        variables: {
          payload: {
            ...payload,
            module_id: module.id,
            published: true,
            weight: options.length,
          },
        },
      });
      if (result) {
        message.success('Successfully created option');
        setEditRow(false);
        setNewOption(false);
        loadModule();
      }
    } catch (e) {
      message.error('Something went wrong');
    }
  };
  const handleUpdate = async (optionId, payload) => {
    try {
      const result = await updateOption({
        variables: { option_id: optionId, payload },
      });
      if (result) {
        message.success('Successfully updated option');
        setEditRow(false);
        loadModule();
      }
    } catch (e) {
      message.error('Something went wrong');
    }
  };
  const handleSubmit = async (optionForm, record) => {
    const payload = await optionForm.validateFields();
    if (record.id) await handleUpdate(record.id, payload);
    else await handleCreate(payload);
    loadModule();
  };

  const tableColumns = useMemo(() => {
    const columns = [
      {
        title: t('options.table.order'),
        dataIndex: 'weight',
        editable: false,
        width: 100,
        render: (text, record) => (
          <>
            <Button type="link" onClick={() => handleOrderUp(record)}>
              <Icon type="up" />
            </Button>
            <Button type="link" onClick={() => handleOrderDown(record)}>
              <Icon type="down" />
            </Button>
          </>
        ),
      },
      {
        title: t('options.table.title'),
        dataIndex: 'title',
        editable: true,
        onCell: (record) => ({
          record,
          dataIndex: 'title',
          title: 'Title',
          editing: editRow === record.key,
        }),
      },
      {
        title: t('options.table.subtitle'),
        dataIndex: 'subtitle',
        editable: true,
        onCell: (record) => ({
          record,
          dataIndex: 'subtitle',
          title: 'Subtitle',
          editing: editRow === record.key,
        }),
      },
      {
        title: '',
        key: 'action_edit',
        width: 100,
        render: (text, record) => {
          const editable = editRow === record.key;
          return editable ? (
            <EditableOptionContext.Consumer>
              {(optionForm) => (
                <>
                  <Button type="link" size="small" onClick={() => handleSubmit(optionForm, record)}>
                    <Icon type="save" />
                  </Button>
                  <Button type="link" size="small" onClick={() => setEditRow(false)}>
                    <Icon type="close" />
                  </Button>
                </>
              )}
            </EditableOptionContext.Consumer>
          ) : (
            <>
              {editDisabled ? (
                <Tooltip
                  title="You have made changes to the module form. Please update the module, before editing options"
                >
                  <Button disabled type="link" size="small" onClick={() => handleEditClick(record)}>
                    <Icon type="edit" />
                  </Button>
                </Tooltip>
              ) : (
                <Button type="link" size="small" onClick={() => handleEditClick(record)}>
                  <Icon type="edit" />
                </Button>
              )}
              <Button type="link" size="small" onClick={() => handleDeleteClick(record)}>
                <Icon type="delete" />
              </Button>
            </>
          );
        },
      },
    ];

    if (moduleSubType === 'photo') {
      columns.splice(1, 0, {
        title: 'Image',
        dataIndex: 'image',
        width: 200,
        onCell: (record) => ({
          record,
          dataIndex: 'image',
          title: 'Image',
          editing: editRow === record.key,
        }),
        render: (text, record) => {
          if (record.image) {
            return <Avatar size="large" shape="square" src={`${staticUrl}/${record.image}`} />;
          }
          return false;
        },
      });
    }
    return columns;
  }, [options, editRow, setEditRow, moduleSubType, editDisabled, handleSubmit, handleEditClick, handleDeleteClick, handleOrderUp, handleOrderDown]);
  const normalizeOptionsWeights = (optionsToNormalize) => {
    if (!orderChanged) {
      let weightHelper = 0;
      optionsToNormalize.forEach(async (option) => {
        weightHelper += 1;
        if (option.weight !== weightHelper) {
          await updateOption({
            variables: {
              option_id: option.id,
              payload: { weight: weightHelper },
            },
          });
        }
      });
    }
    setNormalized(true);
  };
  useEffect(() => {
    if (!moduleOptions) return;
    if (!normalized) normalizeOptionsWeights(moduleOptions);

    const optionsData = moduleOptions.map((option) => ({
      key: shortid.generate(),
      id: option.id,
      title: option.title,
      subtitle: option.subtitle,
      image: option.image,
      weight: option.weight,
    }));
    if (!newOption) {
      setOptions(optionsData);
      return;
    }

    const key = shortid.generate();
    const newOptionPlaceHolder = {
      key,
      title: '',
      subtitle: '',
      image: '',
    };
    setOptions([newOptionPlaceHolder, ...optionsData]);
    setEditRow(key);
  }, [moduleOptions, normalized, setOptions, newOption, orderChanged, setEditRow]);
  useEffect(() => {
    if (subTypeChanged) {
      setEditDisabled(true);
    } else {
      setEditDisabled(false);
    }
  }, [subTypeChanged]);

  return (
    <div style={{ marginTop: 24 }}>
      <header className="page-header">
        <h2>Options</h2>
        <Button type="primary" size="default" onClick={createOption}>
          {t('options.buttons.add_new')}
        </Button>
      </header>
      <EditableOptionContext.Provider value={form}>
        <Table
          columns={tableColumns}
          dataSource={options}
          components={components}
          form={form}
          pagination={{
            current: currentPage,
            pageSize: currentPageSize,
            onChange(page, pageSize) {
              setCurrentPage(page);
              setCurrentPageSize(pageSize);
            },
            onShowSizeChange(page, pageSize) {
              setCurrentPage(page);
              setCurrentPageSize(pageSize);
            },
            showSizeChanger: true,
            pageSizeOptions: ['20', '30', '50', '100'],
          }}
        />
      </EditableOptionContext.Provider>
    </div>
  );
}

export default Form.create()(ListOptions);
