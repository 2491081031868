import { Tag } from 'antd';
import React from 'react';
import { ModuleType, ModuleTypeNames } from '../../enums/module.enums';

function ModuleTypeTag({ type, subtype }) {
  switch (type) {
    case ModuleType.Vote:
      if (subtype === 'photo') return <Tag color="orange">Photo {ModuleTypeNames.Vote}</Tag>;
      return <Tag color="magenta">Text {ModuleTypeNames.Vote}</Tag>;
    case ModuleType.Reaction:
      return <Tag color="volcano">{ModuleTypeNames.Reaction}</Tag>;
    case ModuleType.Comments:
      return <Tag color="blue">{ModuleTypeNames.Comments}</Tag>;
    case ModuleType.QuestionsAndAnswers:
      return <Tag color="geekblue">{ModuleTypeNames.QuestionsAndAnswers}</Tag>;
    case ModuleType.Moodmeter:
      return <Tag color="purple">{ModuleTypeNames.Moodmeter}</Tag>;
    case ModuleType.WordCloud:
      return <Tag color="green">{ModuleTypeNames.WordCloud}</Tag>;
    case ModuleType.Banner:
      return <Tag color="gold">{ModuleTypeNames.Banner}</Tag>;
    default:
      return null;
  }
}

export default ModuleTypeTag;
