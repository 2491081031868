import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

const InvalidContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export function PresenterInvalid() {
  const { t } = useTranslation();

  return (
    <InvalidContainer>
      <h1 style={{ fontWeight: 500, marginBottom: '1rem' }}>
        {t('common.presenter.invalid_url')}
      </h1>
      <Button href="/" type="primary">
        {t('common.presenter.return_home')}
      </Button>
    </InvalidContainer>
  );
}
