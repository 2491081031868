import React, { useMemo } from 'react';
import { Card, Col, DatePicker, Form, Row, Select } from 'antd';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useModuleFields } from '../../../ModuleForm/ModuleForm.hooks';

const Label = styled.span`
  margin: 0 12px;
  display: inline-flex;
  align-items: center;
`;

const { Option } = Select;

function ScheduleCollapse({ module, form, onChange }) {
  const { t } = useTranslation();
  const { getFieldDecorator, getFieldValue } = form;

  // If you only want to show this subform for certain module types, do so here
  const moduleFields = useModuleFields(getFieldValue('type'));

  // Decide if we should display the schedule fields
  const visible = useMemo(() => !!moduleFields, [moduleFields]);
  if (!visible) return null;

  // Pre-fill the user’s time zone if none is specified
  const guessedZone = useMemo(() => moment.tz.guess(), []);
  const defaultTimezone = module.timezone || guessedZone || 'UTC';

  // Helper to build date field config
  const createDateFieldConfig = (fieldKey) => ({
    initialValue: module[fieldKey]
      ? moment.utc(module[fieldKey]) // parse as UTC
      : null,

    getValueProps: (value) => {
      // Convert the stored UTC moment to the user-selected tz for display
      const tz = getFieldValue('timezone') || 'UTC';
      return {
        value: value ? value.clone().tz(tz) : null,
      };
    },

    getValueFromEvent: (pickedMoment) => {
      if (!pickedMoment) return null;
      const tz = getFieldValue('timezone') || 'UTC';

      // Reinterpret the picked date/time in tz, then convert to UTC
      const dateAsTz = moment.tz(
        pickedMoment.format('YYYY-MM-DD HH:mm'),
        'YYYY-MM-DD HH:mm',
        tz,
      );
      return dateAsTz.utc();
    },
  });

  return (
    <Card style={{ marginBottom: 16 }}>
      <Row type="flex" gutter={16}>
        <Col span={24}>
          <Form.Item label={t('modules.form.schedule_start.label')}>
            {getFieldDecorator('schedule_start', {
              ...createDateFieldConfig('schedule_start'),
              onChange,
            })(
              <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm"
                style={{ width: '100%' }}
              />,
            )}
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label={t('modules.form.schedule_end.label')}>
            {getFieldDecorator('schedule_end', {
              ...createDateFieldConfig('schedule_end'),
              onChange,
            })(
              <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm"
                style={{ width: '100%' }}
              />,
            )}
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item label={t('modules.form.timezone.label')}>
            {getFieldDecorator('timezone', {
              // Use the guessed zone if there's no module.timezone
              initialValue: defaultTimezone,
              onChange,
            })(
              <Select showSearch style={{ width: '100%' }}>
                {moment.tz.names().map((tzName) => (
                  <Option key={tzName} value={tzName}>
                    {tzName}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
}

export default ScheduleCollapse;
