import React, { useContext, useEffect, useMemo } from 'react';
import { useEventChanges } from '../components/Presenter/hooks/useModuleUpdates';
import { useGetEvent } from '../hooks/api/events';

const EventContext = React.createContext({});
/** @returns {{ event, loading, load }} */
export const useEventContext = () => useContext(EventContext);

export function EventProvider({ eventId, refetch = 5 * 1000, children }) {
  const [event, { load, loading }] = useGetEvent(eventId);
  useEventChanges(eventId, () => load(eventId), [eventId]);

  useEffect(() => {
    if (!refetch) return () => {};
    if (!eventId) return () => {};

    const interval = setInterval(load, refetch);
    return () => clearInterval(interval);
  }, [refetch, eventId, load]);

  useEffect(() => load(eventId), [eventId]);

  const contextValue = useMemo(() => ({ event, load, loading }), [event, load, loading]);

  return (
    <EventContext.Provider value={contextValue}>
      {children}
    </EventContext.Provider>
  );
}
