import React, { useContext, useEffect, useMemo } from 'react';
import { useSessionChanges } from '../components/Presenter/hooks/useModuleUpdates';
import useGetSession from '../hooks/api/sessions/useGetSession';

const SessionContext = React.createContext({});

/** @returns {{ session, loading, load }} */
export const useSessionContext = () => useContext(SessionContext);

export function SessionProvider({ children, sessionId, refetch = 5 * 1000 }) {
  const [session, { load, loading }] = useGetSession(sessionId);
  useSessionChanges(sessionId, () => load(sessionId), [sessionId]);

  useEffect(() => {
    if (!refetch) return () => {};
    if (!sessionId) return () => {};

    const interval = setInterval(load, refetch);
    return () => clearInterval(interval);
  }, [refetch, sessionId, load]);

  useEffect(() => load(sessionId), [sessionId]);

  const contextValue = useMemo(() => ({ session, loading, load }), [session, loading, load]);

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
}
