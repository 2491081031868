import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import BAD_WORDS from '../../../graphql/queries/badWords';
import SETTING_ADD_UPDATE from '../../../graphql/mutations/settingAddOrUpdate';

function SettingsList() {
  const [badWords, setBadWords] = useState('');

  // graphql
  const { data } = useQuery(BAD_WORDS, {
    fetchPolicy: 'cache-and-network',
  });

  const [updateSetting] = useMutation(SETTING_ADD_UPDATE);

  useEffect(() => {
    if (data && data.badWords) {
      setBadWords(data.badWords.value);
    }
  }, [data]);

  const onClick = async () => {
    await updateSetting({
      variables: {
        payload: {
          key: 'bad_words',
          value: badWords,
        },
      },
    });
  };

  return (
    <div>
      <h1>Settings</h1>
      <p>
        <b>Bad words list:</b>
      </p>
      <textarea rows={20} cols={40} value={badWords} onChange={(e) => setBadWords(e.target.value)} />
      <button onClick={onClick} type="button">
        Update settings
      </button>
    </div>
  );
}

export default SettingsList;
