import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import useLogin from '../../hooks/useLogin';
import LOGIN from '../../graphql/mutations/login';

export default function () {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [apiLogin, { error }] = useMutation(LOGIN);

  const { login } = useLogin();
  const { t } = useTranslation();

  const loginHandler = async (e) => {
    e.preventDefault();

    // try to login to the backend
    const { data } = await apiLogin({
      variables: {
        payload: {
          email,
          password,
        },
      },
    });

    // save the user data in the state
    if (data) {
      login(data.login);
    }
  };

  const inputClasses = ['form-control', 'full-width'];
  const formGroupClasses = ['form-group'];
  let errorDescriptionContainerClasses = ['hidden'];
  if (error) {
    formGroupClasses.push('has-error');
    errorDescriptionContainerClasses = errorDescriptionContainerClasses.filter((className) => className !== 'hidden');
  }
  const renderForm = () => (
    <>
      <div className={formGroupClasses.join(' ')}>
        <div className="input-group">
          <input
            type="email"
            className={inputClasses.join(' ')}
            name="email"
            id="email"
            placeholder={t('login.form.field_username')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>

      <div className={formGroupClasses.join(' ')}>
        <div className="input-group">
          <input
            type="password"
            className={inputClasses.join(' ')}
            name="password"
            id="password"
            placeholder={t('login.form.field_password')}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
    </>
  );

  return (

    <div>
      <form>
        {renderForm()}
        <div className={errorDescriptionContainerClasses}>
          <p className="font-italic text-color-danger">{t('login.form.invalid_user_password')}</p>
        </div>
        <div className="form-group">
          <div className="checkbox">
            {/* <label><input type="checkbox" name="remember_me" /> Remember me</label> */}
          </div>
        </div>
        <div className="form-group">
          <button
            type="submit"
            onClick={loginHandler}
            className="btn btn-secondary d-block full-width text-color-primary fw-bold"
            disabled={!email || !password}
          >
            {t('login.form.login_button')}
          </button>
        </div>
        <div className="form-group ">
          <div className="text-color-primary btn-sm d-block fw-bold">
            {t('login.form.forgot_password')}
            {t('login.form.get_in_touch')} <br />
            <a className="btn btn-link btn-sm" href="mailto:support@votemo.eu">support@votemo.eu</a>

          </div>
        </div>
      </form>
    </div>
  );
}
