import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, Icon, message, Modal, Popconfirm, Switch, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import useLogin from '../../hooks/useLogin';

import SESSIONS_BY_CLIENT from '../../graphql/queries/listSessionsByClient';
import DELETE_SESSION from '../../graphql/mutations/deleteSession';
import UPDATE_SESSION from '../../graphql/mutations/updateSession';
import DUPLICATE_SESSION from '../../graphql/mutations/duplicateSession';

// @todo: Should probably makes the queries with limits.
function ListSessionsPage() {
  const { t } = useTranslation();
  // we need user object here
  const { user, isModerator } = useLogin();
  const { confirm } = Modal;
  let structuredTableData;
  const [editSessionId, setEditSessionId] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [deleteSession] = useMutation(DELETE_SESSION, {
    refetchQueries: ['sessionsByClient'],
  });
  const [updateSession] = useMutation(UPDATE_SESSION, {
    refetchQueries: ['sessionsByClient'],
  });
  const [duplicateSession] = useMutation(DUPLICATE_SESSION, {
    refetchQueries: ['sessionsByClient'],
  });

  const {
    loading, error, data,
  } = useQuery(SESSIONS_BY_CLIENT, {
    variables: { client_id: user.client_id },
    skip: !user.client_id,
  });

  const handleLiveChange = async (record) => {
    try {
      await updateSession({
        variables: {
          session_id: record.id,
          payload: {
            is_live: !record.isLive,
          },
        },
      });
      message.success('Session saved');
    } catch (e) {
      message.error('Something went wrong');
    }
  };

  const handleDuplicateClick = (session_id, session_name) => {
    confirm({
      title: 'Are you sure you want to duplicate this session?',
      content: 'This copies the session and its contents by adding "-COPY" to the end of the session name',
      async onOk() {
        const name = `${session_name}-COPY`;
        try {
          const result = await duplicateSession({
            // TODO: refactor episode_id to session_id
            variables: {
              payload: { episode_id: session_id, name },
            },
          });
          if (result) {
            message.success(`Successfully duplicated session ${name}`);
          }
        } catch (e) {
          message.error('Something went wrong');
        }
      },
    });
  };
  const handleDeleteClick = async (e, session_id) => {
    e.stopPropagation();
    try {
      const result = await deleteSession({
        variables: { session_id },
      });
      if (result) {
        const { session } = result.data;
        message.success(`Successfully deleted session: ${session.name}`);
      }
    } catch (err) {
      message.error('Something went wrong');
    }
  };
  const structureTableData = () => {
    const tableData = data.sessions.map((session) => ({
      key: session.id,
      id: session.id,
      event: session.event.name,
      event_id: session.event.id,
      name: session.name,
      description: session.description,
      isLive: session.is_live,
    }));
    // @todo: Should probably get this data via request when needed
    let eventFilters = tableData.map((row) => (
      {
        text: row.event,
        value: row.event,
      }
    ));

    eventFilters = eventFilters.filter((ele, ind) => (
      ind === eventFilters.findIndex((elem) => elem.text === ele.text && elem.value === ele.value)
    ));

    let sessionFilters = tableData.map((row) => (
      {
        text: row.name,
        value: row.name,
      }
    ));
    sessionFilters = sessionFilters.filter((ele, ind) => (
      ind === sessionFilters.findIndex((elem) => elem.text === ele.text && elem.value === ele.value)
    ));
    const columns = [
      {
        title: t('sessions.table.event'),
        dataIndex: 'event',
        sorter: (a, b) => a.event.localeCompare(b.event),
        filters: eventFilters,
        width: 350,
        onFilter: (value, record) => record.event.indexOf(value) === 0,
        onCell: () => ({
          onClick: (event) => {
            event.stopPropagation();
          },
        }),
        render: (text, record) => {
          let textOutput = text;
          if (text.length > 200) {
            textOutput = (
              <Tooltip title={text}>
                {text.slice(0, 197).padEnd(200, '.')}
              </Tooltip>
            );
          }
          return (
            <Button type="primary" size="default" href={`/show/${record.event_id}/edit`}>
              {textOutput}
            </Button>
          );
        },
      },
      {
        title: t('sessions.table.name'),
        dataIndex: 'name',
        width: 350,
        sorter: (a, b) => a.name.localeCompare(b.name),
        filters: sessionFilters,
        onFilter: (value, record) => record.name.indexOf(value) === 0,
        render: (text) => {
          if (text.length > 200) {
            return (
              <Tooltip title={text}>
                {text.slice(0, 197).padEnd(200, '.')}
              </Tooltip>
            );
          }
          return text;
        },
      },
      {
        title: t('sessions.table.description'),
        dataIndex: 'description',
        width: 200,
        render: (text) => {
          if (text.length > 25) {
            return (
              <Tooltip title={text}>
                {text.slice(0, 17).padEnd(20, '.')}
              </Tooltip>
            );
          }
          return text;
        },
      },
      {
        title: isModerator ? '' : t('sessions.table.is_live'),
        key: 'action_isLive',
        width: 100,
        onCell: () => ({
          onClick: (event) => {
            event.stopPropagation();
          },
        }),
        render: (text, record) => {
          if (isModerator) {
            return '';
          }
          return (
            <Switch
              onChange={() => handleLiveChange(record)}
              defaultChecked={record.isLive}
              checked={record.isLive}
            />
          );
        },
      },
      {
        title: '',
        key: 'action',
        width: 200,
        onCell: () => ({
          onClick: (event) => {
            event.stopPropagation();
          },
        }),
        render: (text, record) => {
          if (isModerator) {
            return '';
          }
          return (
            <>
              <Tooltip title="Duplicates this session">
                <Button type="link" size="small" onClick={() => handleDuplicateClick(record.id, record.name)}>
                  <Icon type="copy" />
                </Button>
              </Tooltip>
              <Popconfirm
                title="Are you sure you want to delete this session?"
                onConfirm={(event) => handleDeleteClick(event, record.id)}
              >
                <Button type="link" size="small">
                  <Icon type="delete" />
                </Button>
              </Popconfirm>
            </>
          );
        },
      },
    ];

    return {
      columns,
      tableData,
    };
  };

  if (loading) {
    return 'Loading ...';
  }

  if (error) {
    return 'Error';
  }
  // if a edit session id has been set, redirect to edit page
  if (editSessionId) {
    return <Redirect push to={`/sessions/${editSessionId}`} />;
  }

  if (data && data.sessions) {
    structuredTableData = structureTableData();
  }
  return (
    <div>
      <header className="page-header">
        <h1>{t('sessions.labels.main')}</h1>
        {user.client_id && !isModerator && (
          <Button type="primary" size="default" href="/sessions/create">
            {t('sessions.buttons.add_new')}
          </Button>
        )}
      </header>
      {data && data.sessions && (
        <Table
          columns={structuredTableData.columns}
          dataSource={structuredTableData.tableData}
          pagination={{
            current: currentPage,
            pageSize: currentPageSize,
            pageSizeOptions: ['10', '20', '30', '50', '100'],
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setCurrentPageSize(pageSize);
            },
            onShowSizeChange: (page, pageSize) => {
              setCurrentPage(page);
              setCurrentPageSize(pageSize);
            },
          }}
          onRow={(record) => ({
            onClick: () => {
              setEditSessionId(record.id);
            },
          })}
        />
      )}
    </div>
  );
}

export default ListSessionsPage;
