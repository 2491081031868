import React, { createContext, useContext, useState, useCallback, useMemo } from 'react';

const ColorThemeContext = createContext({});

export const useColorThemeContext = () => useContext(ColorThemeContext);

export function ColorThemeProvider({ children }) {
  const [isLightTheme, setIsLightTheme] = useState(() => {
    const chosenTheme = localStorage.getItem('isLightTheme');
    return chosenTheme === 'true';
  });

  const handleThemeSwitch = useCallback(() => {
    setIsLightTheme((prevTheme) => {
      const newTheme = !prevTheme;
      localStorage.setItem('isLightTheme', newTheme);
      return newTheme;
    });
  }, []);

  const contextValue = useMemo(
    () => ({ isLightTheme, handleThemeSwitch }),
    [isLightTheme, handleThemeSwitch],
  );

  return (
    <ColorThemeContext.Provider value={contextValue}>
      {children}
    </ColorThemeContext.Provider>
  );
}
