import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import UserForm from './Form';
import USER_ADD from '../../graphql/mutations/userAdd';

function UserAdd() {
  // local state variables
  const [redirect, setRedirect] = useState('');
  const [apiError, setApiError] = useState('');

  // graphql
  const [addUser] = useMutation(USER_ADD, {
    refetchQueries: ['userList'],
  });

  const onSubmit = async (payload) => {
    try {
      const result = await addUser({
        variables: {
          payload,
        },
      });

      if (result) {
        setRedirect('/users');
      }
    } catch (err) {
      if (err.graphQLErrors && err.graphQLErrors.length > 0) {
        setApiError(err.graphQLErrors[0].message);
      } else {
        setApiError('Unknown error occured');
      }
    }
  };

  if (redirect) {
    return <Redirect push to={redirect} />;
  }

  return (
    <div>
      <h1>Adding new user</h1>
      <UserForm onSubmit={onSubmit} error={apiError} />
    </div>
  );
}

export default UserAdd;
