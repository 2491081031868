import React from 'react';
import styled from 'styled-components/macro';
import VoteResult from './VoteResult';

const VoteResultsContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;
  padding-bottom: 16px;

  @media (min-width: 1024px) {
    gap: 32px;
    padding-bottom: 24px;
  }

  @media (min-width: 1440px) {
    gap: 42px;
    padding-bottom: 32px;
  }
`;

const sortByPercentage = (a, b, total) => {
  const aPercentage = Math.round((a.totalCount / total) * 100);
  const bPercentage = Math.round((b.totalCount / total) * 100);

  if (aPercentage < bPercentage) {
    return 1;
  }

  if (aPercentage > bPercentage) {
    return -1;
  }

  return 0;
};

function VoteResults({ votes, total, primaryColor, secondaryColor }) {
  if (!votes) return null;

  return (
    <VoteResultsContainer
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
    >
      {
        votes.sort((a, b) => sortByPercentage(a, b, total)).map(({ id, option, totalCount }) => (
          <VoteResult
            key={id}
            title={option.title}
            subtitle={option.subtitle}
            image={option.image}
            total={total}
            count={totalCount}
            color={`#${primaryColor}`}
          />
        ))
      }
    </VoteResultsContainer>
  );
}

export default VoteResults;
