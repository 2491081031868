import { Button as AntButton, Card, Col, Icon, Input as AntInput, Row } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { ModuleType } from '../../../../enums/module.enums';

const Label = styled.span`
  margin-bottom: 16px;
  display: inline-flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
`;
const RightSpan = styled.div`
  padding: 0 8px 8px;
`;
const Input = styled(AntInput)`
  margin-bottom: 8px;

  .ant-input-group-addon {
    padding: 0;
  }
`;

const AddonButton = styled(AntButton)`
  border: none;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  padding: 0 16px;
  height: 30px;
`;

const DEFAULT_LABELS = ['Low', 'Medium', 'High'];

function MoodmeterOptionsCollapse({ form, initialValue, onChange }) {
  const { t } = useTranslation();
  const { getFieldValue } = form;

  const [labels, setLabels] = useState(initialValue || DEFAULT_LABELS);
  const [saved, setSaved] = useState(true);
  const inputRef = useRef();

  useEffect(() => {
    if (!initialValue) return;
    setLabels(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (saved) return;
    if (!labels) return;
    const valid = labels.every(
      (label) => label.trim().length > 0,
    );
    if (valid) {
      onChange(labels);
      setSaved(true);
    }
  }, [labels, onChange]);

  const handleRemoveLabel = useCallback((index) => {
    setLabels((previous) => {
      const next = [...previous];
      next.splice(index, 1);
      return next;
    });
    setSaved(false);
  }, [setLabels]);

  const handleInputBlur = useCallback((index, event) => {
    const { value } = event.target;
    setLabels((prevLabels) => {
      const nextLabels = [...prevLabels];
      nextLabels[index] = value;
      return nextLabels;
    });
    setSaved(false);
  }, [setLabels]);

  const handleAddLabel = useCallback(() => {
    const { value } = inputRef.current.input;
    if (!value.trim()) return;
    // noinspection JSUnresolvedFunction
    inputRef.current.handleReset();
    setLabels((prevLabels) => [value, ...prevLabels]);
    setSaved(false);
  }, [setLabels, setSaved]);

  if (getFieldValue('type') !== ModuleType.Moodmeter) return null;
  if (!labels) return null;

  return (
    <Card style={{ marginBottom: 16 }}>
      <Row type="flex" gutter={16}>
        <Col span={24}>
          <Label>{t('modules.form.moodmeter_labels.label')}</Label>
          <Input
            placeholder={t('modules.form.moodmeter_labels.placeholder')}
            ref={inputRef}
            addonAfter={(
              <AddonButton size="small" onClick={handleAddLabel}>
                <Icon type="plus" />
              </AddonButton>
            )}
          />
          <RightSpan>Highest</RightSpan>
          {[...labels].reverse().map((label, index) => (
            <Input
              key={label || `label-empty-${index}`}
              defaultValue={label}
              placeholder={t('modules.form.moodmeter_labels.placeholder')}
              onBlur={(e) => handleInputBlur(labels.length - 1 - index, e)}
              addonAfter={(
                <AddonButton type="danger" size="small" onClick={() => handleRemoveLabel(labels.length - 1 - index)}>
                  <Icon type="delete" />
                </AddonButton>
              )}
            />
          ))}
          <RightSpan>Lowest</RightSpan>
        </Col>
      </Row>
    </Card>
  );
}

export default MoodmeterOptionsCollapse;
