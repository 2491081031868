import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Table } from 'antd';

import useLogin from '../../hooks/useLogin';
import { useModulesTable } from './ModulesList.hooks';
import { useEvent } from '../../hooks/providers/events';
import { useSession, useSessionModules } from '../../hooks/providers/sessions';

function ModulesList() {
  const history = useHistory();
  const { isModerator } = useLogin();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [event] = useEvent();
  const [session] = useSession();
  const [, { reloadModules }] = useSessionModules(false);
  const { columns, dataSource } = useModulesTable(reloadModules);

  const sessionPath = useMemo(
    () => `/events/${event?.id}/sessions/${session?.id}`,
    [event, session],
  );

  if (!event || !session) return null;

  return (
    <div>
      <header className="page-header">
        <h2>Modules</h2>
        {!isModerator && (
          <Button
            type="primary"
            size="default"
            onClick={() => history.push(`${sessionPath}/create-module`)}
            htmlType="button"
          >
            Add new module
          </Button>
        )}
      </header>
      <Table
        columns={columns}
        dataSource={dataSource}
        size="small"
        pagination={{
          current: currentPage,
          pageSize: currentPageSize,
          pageSizeOptions: ['10', '20', '30', '50', '100'],
          showSizeChanger: true,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentPageSize(pageSize);
          },
          onShowSizeChange: (page, pageSize) => {
            setCurrentPage(page);
            setCurrentPageSize(pageSize);
          },
        }}
      />
    </div>
  );
}

export default ModulesList;
