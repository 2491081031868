import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { RootContext } from '../../context/root-context';
import { useEvent, useModule } from '../../hooks/providers';
import PresenterWatermark from './PresenterWatermark';
import PresenterQR from './PresenterQR';
import { useModuleUpdateQuery } from './hooks/useModuleUpdates';
import VOTE_RESULTS from '../../graphql/queries/voteResults';

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: calc(100vh - 84px);
  margin: 16px 0 36px 16px;

  @media (min-width: 1024px) {
    margin: 20px 0 44px 24px;
  }

  @media (min-width: 1440px) {
    margin: 32px 0 52px 42px;
  }
`;

const QRAndAnalyticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Text = styled.span`
  margin-top: 10px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 700;

  :last-of-type {
    margin-top: 0;
  }

  @media (min-width: 1024px) {
    margin-top: 16px;
    font-size: 14px;
    line-height: 19px;
  }

  @media (min-width: 1440px) {
    font-size: 16px;
    line-height: 19px;
  }
`;

const VoteCount = styled.p`
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;

  @media (min-width: 1024px) {
    font-size: 36px;
    line-height: 40px;
  }

  @media (min-width: 1440px) {
    font-size: 42px;
    line-height: 48px;
  }
`;

function PresenterSidebar() {
  const [event] = useEvent();
  const [module] = useModule();
  const [data, { loading }] = useModuleUpdateQuery(
    module.id,
    VOTE_RESULTS,
    useMemo(
      () => ({ module_id: module.id }),
      [module.id],
    ),
  );

  const [state] = useContext(RootContext);

  const isModuleTypeVote = module.type === 'vote';

  const display_qr = useMemo(() => {
    if (!event) return false;
    if (event.display_qr && state.presenter && typeof state.presenter.display_qr === 'boolean') {
      return state.presenter.display_qr;
    }

    return event.display_qr;
  }, [event, state]);

  const totalVotes = useMemo(() => {
    if (!isModuleTypeVote) return 0;
    if (loading) return 0;
    if (!data) return 0;
    if (!data.voteResults) return 0;
    return data.voteResults.totalCount ?? 0;
  }, [loading, data]);

  if (!display_qr) return null;
  if (!event) return null;
  if (!module) return null;

  return (
    <Sidebar>
      <QRAndAnalyticsContainer>
        <PresenterQR />
        {isModuleTypeVote ? (
          <div>
            <Text>Total votes</Text>
            <VoteCount>{totalVotes}</VoteCount>
          </div>
        ) : null}
      </QRAndAnalyticsContainer>
      <PresenterWatermark />
    </Sidebar>
  );
}

export default PresenterSidebar;
