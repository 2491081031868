import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import colorUtils from '../../../../helpers/colorUtils';
import { useEventColors } from '../../../../hooks/useEventColors';
import { useThreads } from './CommentsPresenter.hooks';

import PresenterHead from '../../PresenterHead';
import Comment from './Comment';

const CommentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const CommentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;

  @media (min-width: 1024px) {
    padding-bottom: 24px;
  }

  @media (min-width: 1440px) {
    padding-bottom: 32px;
  }
`;

function CommentsPresenter({ module }) {
  const colors = useEventColors();
  const { data: comments } = useThreads(module.id, module.type);

  const moderatorColor = useMemo(() => {
    if (!colors) return 'black';
    const darkest = colorUtils.closestToBlack(
      colorUtils.hexToRGB(colors.primary),
      colorUtils.hexToRGB(colors.secondary),
    );
    return `#${colorUtils.rgbToHex(darkest)}`;
  }, [colors]);

  return (
    <CommentsContainer>
      <PresenterHead
        title={module.title}
        subtitle={module.subtitle}
        image={module.image}
      />
      <CommentsWrapper
        primaryColor={colors.primary}
        secondaryColor={colors.secondary}
      >
        {comments.map((comment, index) => (
          <Comment
            key={comment.id}
            comment={comment}
            moderatorColor={moderatorColor}
            upvoteColor={colors.primary}
            index={index}
          />
        ))}
      </CommentsWrapper>
    </CommentsContainer>
  );
}

export default CommentsPresenter;
