import { Icon } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import moment from 'moment';

const commentUpvoteColor = ({ color }) => {
  if (!color) return 'black';
  if (color.startsWith('#')) return color;

  return `#${color}`;
};

// TODO: refactor conditional margins once Q&A and Chat module are separated
const CommentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin: ${({ index }) => (index === 0 ? '0 0 10px' : '0 16px 8px 16px')};
  border-radius: 16px;
  padding: 16px;
  background-color: ${({ theme }) => theme.commentBackground};

  &:last-of-type {
    margin-bottom: 0;
  }

  ${({ index }) => index === 0 && css`
    box-shadow: 4px 4px 16px 2px rgba(0, 0, 0, 0.25);
  `}

  @media (min-width: 1024px) {
    gap: 24px;
    padding: 24px;
    margin: ${({ index }) => (index === 0 ? '0 0 12px' : '0 16px 8px 16px')};
  }

  @media (min-width: 1440px) {
    gap: 32px;
    margin: ${({ index }) => (index === 0 ? '0 0 16px' : '0 24px 10px 24px')};
  }
`;

const CommentBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
`;

const CommentHead = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CommentAuthor = styled.strong`
  --color: ${({ color, theme }) => color || theme.text};

  font-size: ${({ index }) => (index === 0 ? '12px' : '10px')};
  line-height: ${({ index }) => (index === 0 ? '19px' : '14px')};
  color: var(--color);

  @media (min-width: 1024px) {
    font-size: ${({ index }) => (index === 0 ? '14px' : '12px')};
    line-height: 19px;
  }

  @media (min-width: 1440px) {
    font-size: ${({ index }) => (index === 0 ? '16px' : '14px')};
  }
`;

const CommentTime = styled.span`
  font-size: 10px;
  line-height: 16px;
`;

const CommentText = styled.span`
  font-size: ${({ index }) => (index === 0 ? '16px' : '12px')};
  line-height: ${({ index }) => (index === 0 ? '20px' : '16px')};
  font-weight: 700;
  color: ${({ theme }) => theme.text};

  @media (min-width: 1024px) {
    font-size: ${({ index }) => (index === 0 ? '20px' : '16px')};
    line-height: ${({ index }) => (index === 0 ? '26px' : '20px')};
  }

  @media (min-width: 1440px) {
    font-size: ${({ index }) => (index === 0 ? '30px' : '24px')};
    line-height: ${({ index }) => (index === 0 ? '34px' : '30px')};
  }
`;

const CommentUpvotes = styled.div`
  display: flex;
  gap: 6px;
  color: ${commentUpvoteColor};
  margin: 0 10px 0 6px;

  span {
    font-size: 12px;
    line-height: 19px;
    color: ${({ theme }) => theme.text};

    @media (min-width: 1024px) {
      font-size: 14px;
    }
  }

  svg {
    height: 20px;
    width: 20px;
    fill: ${commentUpvoteColor};

    @media (min-width: 1024px) {
      height: 24px;
      width: 24px;
    }
  }
`;

// TODO: Clean up inline css
function Comment({ comment, moderatorColor, upvoteColor, index }) {
  const [time, setTime] = useState(0);

  useEffect(() => {
    const updateTime = () => {
      setTime(
        moment.duration(
          moment().diff(moment(comment.createdAt)),
        ).as('minutes'),
      );
    };
    updateTime();
    const interval = setInterval(updateTime, 10 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [comment]);

  const isReply = useMemo(() => comment.parent_id !== 0, [comment]);

  if (comment.is_hidden) {
    return null;
  }

  return (
    <CommentContainer isReply={isReply} index={index}>
      <CommentBody>
        <CommentHead>
          {comment.is_moderated ? (
            <>
              <CommentAuthor color={moderatorColor} index={index}>
                {comment.author}
              </CommentAuthor>
              <Icon type="user" />
            </>
          ) : (
            <CommentAuthor index={index}>
              {comment.author}
            </CommentAuthor>
          )}
          <CommentTime>
            {moment().subtract(time, 'minutes').fromNow()}
          </CommentTime>
        </CommentHead>
        <CommentText index={index}>{comment.text}</CommentText>
      </CommentBody>
      <CommentUpvotes color={upvoteColor}>
        <Icon type="like" theme="filled" />
        <span>{comment.total_upvotes}</span>
      </CommentUpvotes>
    </CommentContainer>
  );
}

export default Comment;
