import { Button, Popover } from 'antd';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ModuleType } from '../../../enums/module.enums';

function NavLinks({ module }) {
  const { t } = useTranslation();

  const href = useMemo(() => {
    if (!module) return null;
    const { session } = module;
    const { event } = session;
    switch (module.type) {
      case ModuleType.Vote:
      case ModuleType.Reaction:
      case ModuleType.WordCloud:
        return `/events/${event.id}/sessions/${session.id}/modules/${module.id}/vote-results`;
      case ModuleType.Comments:
        return `/events/${event.id}/sessions/${session.id}/modules/${module.id}/comments`;
      case ModuleType.QuestionsAndAnswers:
        return `/events/${event.id}/sessions/${session.id}/modules/${module.id}/comments`;
      case ModuleType.Moodmeter:
        return `/events/${event.id}/sessions/${session.id}/modules/${module.id}/meter-results`;
      case ModuleType.ProductLaunch:
        return `/events/${event.id}/sessions/${session.id}/modules/${module.id}/click-results`;
      default:
        return null;
    }
  }, [module]);

  if (!module) {
    return null;
  }

  return (
    <Popover
      content={t('modules.tooltips.results')}
      placement="bottomRight"
      trigger="hover"
    >
      <Button href={href}>
        {t('modules.buttons.results')}
      </Button>
    </Popover>
  );
}

export default NavLinks;
